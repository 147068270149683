(function () {
    angular.module("theatrePricingTableComponent", [])
    .component("theatrePricingTable",
    {
        templateUrl: "/Scripts/app/theatre-pricing-table/theatrePricingTable.template.1.html",
        bindings: {
            todayText: "@",
            tomorrowText: "@",
            jsonData: "@",
            languageCode: "@"
        },
        controller: ["$scope", "$filter", "$sce", theatreAdmissionPricingController]
    });

    function theatreAdmissionPricingController($scope, $filter, $sce) {
        var vm = this;

        vm.$onInit = initFunction;

        vm.selectDate = selectDate;

        function initFunction() {
            vm.showDateSelection = true;
            mapData(JSON.parse(vm.jsonData));
            
            vm.selectedDatePricing = vm.pricingData[0];
        }

        function mapData(jsonData) {
            vm.pricingData = mapTheatrePricingInfo(jsonData.theatreAdmissionPriceDateList);
            vm.additionalPricingInfo = jsonData.message != null ? jsonData.message : "";
        }

        function mapTheatrePricingInfo(theatrePricingData) {
            var theatrePricingArray = [];
            theatrePricingData.forEach(function (dataForDate) {
                theatrePricingArray.push(new dataForDateObject(dataForDate))
            });
            return theatrePricingArray;
        }

        function dataForDateObject(configObject) {
            this.ageGroups = configObject.ageGroups;
            this.ages = configObject.ages;
            this.date = formatTheatrePricingDate(configObject.date);
            this.formats = mapFormats(configObject.formats);
        }

        function formatTheatrePricingDate(dateItem) {
            //Hacky addition to hide date if date specific value
            if (dateItem.substr(0, 4) === "0001") {
                vm.showDateSelection = false;
                return "";
            }
            else {
                var utcTime = moment.utc(dateItem),
                    localTime = moment(),
                    tomorrowTime = moment().add(1, 'days'),
                dateItemFormatted,
                localTimeFormatted,
                tomorrowFormatted;

                if (vm.languageCode === "fr-ca") {
                    utcTime.locale('fr');
                    localTime.locale('fr');
                    tomorrowTime.locale('fr');
                    dateItemFormatted = utcTime.format("ddd D MMM YYYY");
                    localTimeFormatted = localTime.format("ddd D MMM YYYY");
                    tomorrowFormatted = tomorrowTime.format("ddd D MMM YYYY");

                }
                else {
                    dateItemFormatted = utcTime.format("ddd, MMM DD YYYY");
                    localTimeFormatted = localTime.format("ddd, MMM DD YYYY");
                    tomorrowFormatted = tomorrowTime.format("ddd, MMM DD YYYY");
                }

                if (dateItemFormatted === localTimeFormatted) {
                    return vm.todayText + " (" + dateItemFormatted + ")";
                }
                else if (dateItemFormatted === tomorrowFormatted) {
                    return vm.tomorrowText + " (" + dateItemFormatted + ")";
                }
                else {
                    return dateItemFormatted;
                }
            }
        }

        function mapFormats(configObjectArray) {
            var formatArray = [];
            configObjectArray.forEach(function (formatObj) {
                formatArray.push(new formatObject(formatObj));
            });
            return formatArray;
        }

        function formatObject(configObject) {
            this.cssClass = configObject.cssClass;
            this.format = configObject.format;
            this.prices = makePriceArray(configObject.prices, configObject.message);
            return this;
        }

        function makePriceArray(prices, noPriceMessage) {
            prices.forEach(function (price, index, priceArray) {
                if (price === 0)
                    priceArray[index] = noPriceMessage != null ? noPriceMessage : "---";
                else if (vm.languageCode !== "fr-ca") {
                    priceArray[index] = "$" + parseFloat(priceArray[index]).toFixed(2);
                }
                else {
                    priceArray[index] = ((parseFloat(priceArray[index]).toFixed(2)) + " $").replace(".", ",");
                }
            });
            return prices;
        }

        function selectDate(dateObject) {
            vm.selectedDatePricing = dateObject;
        }

    }

})();