angular.module("theatresServiceModule", [])
    .service("theatresService", ["$http", "$q", "base64Encoder", function ($http, $q, base64Encoder) {

    var self = this;

    self.getAllTheatres = function (languageCode) {

        var deferredRequest = $q.defer();

        var parameters = {
            language: languageCode,
            range: 100000,
            skip: 0,
            take: 1000
        }

        $http({
            url: js.settings.EnableSecureApiUrl == "true" ?  js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl,
            params: parameters,
            method: 'GET',
            headers: {
                    'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
            }
        }).then(requestSuccess, requestFailure);

        return deferredRequest.promise;

        function requestSuccess(resp) {
            var mappedData;
            try {
                mappedData = mapTheatresResponse(resp.data);
                deferredRequest.resolve(mappedData);
            }
            catch (error) {
                deferredRequest.reject(error);
            }
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

    self.getLocalTheatres = function () {

        var deferredRequest = $q.defer();
        $http({
            url: "/QuickTicket/GetNearByTheatres",
            method: 'GET'
        }).then(requestSuccess, requestFailure);

        return deferredRequest.promise;

        function requestSuccess(resp) {
            try {
                var mappedData = mapLocalTheatresResponse(resp);
                deferredRequest.resolve(mappedData);
            }
            catch (error) {
                deferredRequest.reject(error);
            }
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

    self.getLocalTheatresNew = function (language, distance) {

        var deferredRequest = $q.defer(),
            longitude,
            latitude;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getPositionSuccess, getPositionFail);
        }
        else {
            getPositionFail();
        }

        return deferredRequest.promise;

        function getPositionSuccess(position) {
            var parameters = {
                range: distance,
                language: language,
                location: {
                    latitude: position.latitude,
                    longitude: position.longitude
                }
            }

            $http({
                url: js.settings.EnableSecureApiUrl == "true" ?  js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl,
                params: parameters,
                method: 'GET',
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                }
            }).then(requestSuccess, requestFailure);
        }

        function getPositionFail() {
            var parameters = {
                range: distance,
                language: language
            }

            $http({
                url: js.settings.EnableSecureApiUrl == "true" ?  js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl,
                params: parameters,
                method: 'GET',
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                }
            }).then(requestSuccess, requestFailure);
        }

        function requestSuccess(resp) {
            var mappedData;
            try {
                mappedData = mapTheatresResponse(resp.data);
                deferredRequest.resolve(mappedData);
            }
            catch (error) {
                deferredRequest.reject(error);
            }
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

        self.getMovieTheatres = function (filmId, language) {

        var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.MovieApiUrl : js.settings.OldMovieApiUrl)
            + "/" + filmId + "/allavailabletheatresoneposter/dates",
            requestObject = {
                language: language,
                skip: 0,
                take: 1000
            };

        var deferredRequest = $q.defer();

        $http({
            url: requestUri,
            params: requestObject,
            method: 'GET',
            headers: {
                'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
            }
        }).then(requestSuccess, requestFailure);

        return deferredRequest.promise;

        function requestSuccess(resp) {
            try {
                var mappedData = mapTheatreWithShowtimes(resp.data);
                deferredRequest.resolve(mappedData);
            }
            catch (error) {
                deferredRequest.reject(error);
            }
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };


    self.getTheatresWithFavs = function (languageCode, connectGuid, ccToken) {

        var deferredRequest = $q.defer();
        var parameters = {
            language: languageCode,
            range: 100000,
            skip: 0,
            take: 1000
        };
        var authorizationString = "Basic " + base64Encoder.encode(connectGuid + ":" + ccToken);

        $http({
            url: js.settings.EnableSecureApiUrl == "true" ?  js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl,
            params: parameters,
            method: 'GET',
            headers: {
                Authorization: authorizationString,
                'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
            }
        }).then(requestSuccess, requestFailure);

        return deferredRequest.promise;

        function requestSuccess(resp) {
            try {
                var mappedData = mapTheatresResponse(resp.data);
                deferredRequest.resolve(mappedData);
            }
            catch (error) {
                deferredRequest.reject(error);
            }
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

    self.getMovieTheatresWithFavs = function (filmId, language, connectGuid, ccToken) {

        var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.MovieApiUrl : js.settings.OldMovieApiUrl)
            + "/" + filmId + "/allavailabletheatresoneposter/dates",
            parameters = {
                language: language,
                skip: 0,
                take: 1000
            };

        var deferredRequest = $q.defer();
        var authorizationString = "Basic " + base64Encoder.encode(connectGuid + ":" + ccToken);

        $http({
            url: requestUri,
            params: parameters,
            method: 'GET',
            headers: {
                Authorization: authorizationString,
                'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
            }
        }).then(requestSuccess, requestFailure);

        return deferredRequest.promise;

        function requestSuccess(resp) {
            try {
                var mappedData = mapTheatreWithShowtimes(resp.data);
                deferredRequest.resolve(mappedData);
            }
            catch (error) {
                deferredRequest.reject(error);
            }
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };



    function mapTheatresResponse(serverData) {
        var mappedData = [];

        serverData.data.forEach(function (theatre) {
            mappedData.push(new theatreObject(theatre));
        });

        return mappedData;
    }

    function mapLocalTheatresResponse(serverData) {
        var mappedData = [];

        serverData.data.forEach(function (theatre) {
            mappedData.push(new localTheatreObject(theatre));
        });

        return mappedData;
    }

    function mapTheatreWithShowtimes(serverData) {
        var mappedData = [];

        serverData.data.forEach(function (theatre) {
            mappedData.push(new theatreWithShowtimesObject(theatre));
        });

        return mappedData;
    }

    function theatreObject(configObject) {
        this.name = configObject.name;
        this.locationId = configObject.id;
        this.city = configObject.city;
        this.province = configObject.provinceCode;
        this.isDriveIn = configObject.isDriveIn;
        this.isFavourite = configObject.isFavourite;
    }

    function localTheatreObject(configObject) {
        this.name = configObject.Name;
        this.locationId = configObject.LocationId;
        this.city = configObject.City;
        this.province = configObject.Province;
        this.isDriveIn = configObject.IsDriveIn;
    }

    function theatreWithShowtimesObject(configObject) {
        this.name = configObject.theatre.name;
        this.locationId = configObject.theatre.id;
        this.city = configObject.theatre.city;
        this.province = configObject.theatre.provinceCode;
        this.isFavourite = configObject.theatre.isFavourite;
        this.movieUtcDates = configObject.dateTimes;
    }

}]);