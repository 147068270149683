angular.module("theatreFunctionsFactory", [])
.factory("theatreFunctions", ["$q", function ($q) {
    var theatreFactoryObject = {};

    theatreFactoryObject.updateTheatreType = updateTheatreType;
    theatreFactoryObject.updateFavTheatreType = updateFavTheatreType;

    theatreFactoryObject.getHistoricalTheatreSearches = getHistoricalTheatreSearches;
    theatreFactoryObject.setHistoricalTheatreSearches = setHistoricalTheatreSearches;
    theatreFactoryObject.updateHistoricalTheatreType = updateHistoricalTheatreType;
    theatreFactoryObject.getHistoricalTheatreSearches = getHistoricalTheatreSearches;

    return theatreFactoryObject;

    function updateTheatreType(theatreList, updateTheatreList, typeString) {
        for (var i = 0; i < theatreList.length; i++) {
            for (var j = 0; j < updateTheatreList.length; j++) {
                if (theatreList[i].id === updateTheatreList[j].locationId) {
                    theatreList[i].type = typeString;
                    j = updateTheatreList.length;
                    updateTheatreList.splice(j, 1);
                }
            }
            if (updateTheatreList.length === 0) i = theatreList.length;
        }
        return theatreList;
    }

    function updateFavTheatreType(theatreList, favTheatreList) {
        for (var i = 0; i < theatreList.length; i++) {
            for (var j = 0; j < favTheatreList.length; j++) {
                if (theatreList[i].id === favTheatreList[j].LocationId) {
                    theatreList[i].type = "fav";
                    j = favTheatreList.length;
                    favTheatreList.splice(j, 1);
                }
            }
            if (favTheatreList.length === 0) i = theatreList.length;
        }
        return theatreList;
    }


    function getHistoricalTheatreSearches() {
        var searchedTheatres = [];
        var oldSearchedTheatreStorageValues = (hasStorage() === true) ? localStorage.getItem("PreviouslySearchedTheatres") : null;

        if (oldSearchedTheatreStorageValues !== undefined && oldSearchedTheatreStorageValues !== null) {
            searchedTheatres = oldSearchedTheatreStorageValues.split(",");
        }
        else {
            var oldSearchedTheatresCookieValues = $.cookie("PreviouslySearchedTheatres");
            if (oldSearchedTheatresCookieValues !== undefined && oldSearchedTheatresCookieValues !== null) {
                searchedTheatres = oldSearchedTheatresCookieValues.split(",");
            }
        }
        return searchedTheatres;
    }

    function setHistoricalTheatreSearches(theatreObject, theatres) {
        var searchedTheatres = theatres, theatreObj = theatreObject.toString();
        if (theatreObj !== "undefined") {
            if (searchedTheatres !== undefined && searchedTheatres !== null) {

                var matchedTheatreIndex = searchedTheatres.indexOf(theatreObj);
                if (matchedTheatreIndex > -1) {
                    searchedTheatres.unshift(theatreObj);
                    searchedTheatres = uniqueArray(searchedTheatres);
                } else {
                    if (searchedTheatres.length === 3) {
                        searchedTheatres.pop();
                        searchedTheatres.unshift(theatreObj);
                    } else {
                        searchedTheatres.unshift(theatreObj);
                    }
                }

                if (hasStorage()) {
                    localStorage.setItem("PreviouslySearchedTheatres", searchedTheatres);
                } else {
                    var inOneYear = new Date();
                    inOneYear.setFullYear(inOneYear.getFullYear() + 1);
                    $.cookie("PreviouslySearchedTheatres",
                        searchedTheatres,
                        { domain: '.cineplex.com', expires: inOneYear, path: '/' });
                }
            }
        }
    }

    function updateHistoricalTheatreType(theatreList, updateTheatreList, typeString) {
        for (var i = 0; i < theatreList.length; i++) {
            for (var j = 0; j < updateTheatreList.length; j++) {
                if (theatreList[i].id === parseInt(updateTheatreList[j])) {
                    theatreList[i].type = typeString;
                    j = updateTheatreList.length;
                    updateTheatreList.splice(j, 1);
                }
            }
            if (updateTheatreList.length === 0) i = theatreList.length;
        }
        return theatreList;
    }

    function getHistoricalTheatreSearches() {
        var searchedTheatres = [];
        var oldSearchedTheatreStorageValues = (hasStorage() === true) ? localStorage.getItem("PreviouslySearchedTheatres") : null;

        if (oldSearchedTheatreStorageValues !== undefined && oldSearchedTheatreStorageValues !== null) {
            searchedTheatres = oldSearchedTheatreStorageValues.split(",");
        }
        else {
            var oldSearchedTheatresCookieValues = $.cookie("PreviouslySearchedTheatres");
            if (oldSearchedTheatresCookieValues !== undefined && oldSearchedTheatresCookieValues !== null) {
                searchedTheatres = oldSearchedTheatresCookieValues.split(",");
            }
        }
        return searchedTheatres;
    }

    function hasStorage() {
        try {
            localStorage.setItem("temp", "temp");
            localStorage.removeItem("temp");
            return true;
        }
        catch (exception) {
            return false;
        }
    }

}]);