angular.module('encoderFactory', [])
.factory('base64Encoder', function () {

    var factoryObj = {};
    var Base64KeyString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

    factoryObj.encode = encodeBase64;
    factoryObj.decode = decodeBase64;

    function encodeBase64(input) {
        /**!
*
*  Base64 encode / decode taken from:
*  http://www.webtoolkit.info/
*
**/
        var output = "";
        var chr1, chr2, chr3 = "";
        var enc1, enc2, enc3, enc4 = "";
        var i = 0;

        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                    Base64KeyString.charAt(enc1) +
                    Base64KeyString.charAt(enc2) +
                    Base64KeyString.charAt(enc3) +
                    Base64KeyString.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);

        return output;
    }

    function decodeBase64(input) {
        /**!
*
*  Base64 encode / decode taken from:
*  http://www.webtoolkit.info/
*
**/
        var output = "";
        var chr1, chr2, chr3 = "";
        var enc1, enc2, enc3, enc4 = "";
        var i = 0;

        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                    Base64KeyString.charAt(enc1) +
                    Base64KeyString.charAt(enc2) +
                    Base64KeyString.charAt(enc3) +
                    Base64KeyString.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);

        return output;
    }

    return factoryObj;
});