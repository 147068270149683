function moviesGridControllerBase(moviesService, moviesGrid, $scope, $element, $timeout, vm) {

    var moviesGridDesktopSize = 992,
        moviesGridTabletSize = 768,
        moviesGridMobileSize = 480;

    vm.notInitialized = true;

    vm.error = false;

    vm.noMoviesFound = false;

    vm.$onInit = onInitFunction;

    vm.showMoreMovies = showMoreMovies;

    function onInitFunction() {

        vm.serviceRequestFunction = moviesService[vm.serviceName];
        vm.requestedFilter = "";
        vm.loadingMore = false;
        vm.imagesResized = false;
        vm.moviesGrid = new moviesGrid();
        vm.selectedVideoId = 0;
        vm.screenType = getScreenType();
        vm.stringLimit = getStringLimit();
        vm.initialitems = getInitialNumItemsToDisplay();
        vm.gridClass = getGridClass();

        initializeGrid();

        vm.filterWatch = $scope.$watch(function () {
            return vm.activeFilter;
        },
            activeFilterChangeHandler
        );

        vm.tabOpenWatch = $scope.$watch(function () {
            return vm.isOpen;
        },
            tabOpenWatchHandler
        );

    }

    function initializeGrid() {
        if (vm.notInitialized && vm.isOpen) {
            getMovies(vm.initialitems, 0, vm.requestUri, initMoviesRequestSuccess);
            initWindowResizeBehaviour();
        }
        else if (vm.requestedFilter !== vm.activeFilter.serviceRequestString && vm.isOpen) {
            getMovies(vm.initialitems, 0, vm.requestUri, filterChangeRequestSuccess);
        }
        else if (vm.isOpen) {
            updateGridDisplay();
        }
    }

    function tabOpenWatchHandler(newVal, oldVal) {
        if (newVal !== oldVal) {
            initializeGrid();
        }
    }

    function activeFilterChangeHandler(newVal, oldVal) {
        if (newVal.serviceRequestString !== oldVal.serviceRequestString && newVal.serviceRequestString !== vm.requestedFilter && vm.isOpen) {
            reinitializeGrid();
        }
    }

    function reinitializeGrid() {
        if (!vm.notInitialized) {
            getMovies(vm.initialitems, 0, vm.requestUri, filterChangeRequestSuccess);
        }
    }

    function initWindowResizeBehaviour() {
        var debounceTimer;
        $(window).on("resize", function () {
            clearTimeout(debounceTimer);
            vm.stringLimit = getStringLimit();
            debounceTimer = setTimeout(updateGridDisplay, 250);
        });
    }

    function updateGridDisplay() {
        var oldScreenType = vm.screenType,
            newScreenType = getScreenType();

        if (oldScreenType != newScreenType && vm.isOpen) {
            vm.screenType = newScreenType;
            vm.gridClass = getGridClass();
            fillLastRow();
            $timeout(function () { $scope.$apply(); });
        }
    }

    function fillLastRow() {
        var requestItems = getItemsToFillRow();
        if (requestItems && (vm.moviesGrid.allItems.length + requestItems) < vm.moviesGrid.totalItems) {
            getMovies(requestItems, vm.moviesGrid.allItems.length, vm.requestUri, windowResizeRequestSuccess);
        }
        else {
            vm.moviesGrid.init(vm.moviesGrid.allItems, (vm.initialitems + 1), vm.moviesGrid.totalItems, [[0, vm.itemsPerRowMobile], [moviesGridTabletSize, vm.itemsPerRowTablet], [moviesGridDesktopSize, vm.itemsPerRowDesktop]]);
        }
    }

    function getItemsToFillRow() {
        var itemsPerRow = getItemsPerRow();
        return itemsPerRow - vm.moviesGrid.allItems.length % itemsPerRow - 1;
    }

    function getScreenType() {
        var deviceWidth = window.innerWidth;
        if (deviceWidth < moviesGridTabletSize)
            return 0; //0 For Mobile
        else if (deviceWidth < moviesGridDesktopSize)
            return 1; //1 For Tablet
        else
            return 2; //2 For Desktop
    }

    function getStringLimit() {
        var deviceWidth = window.innerWidth;
        if (deviceWidth < moviesGridTabletSize)
            return vm.stringLimitMobile;
        else if (deviceWidth < moviesGridDesktopSize)
            return vm.stringLimitTablet;
        else
            return vm.stringLimitDesktop;
    }

    function getInitialNumItemsToDisplay() {
        var deviceWidth = window.innerWidth;
        if (deviceWidth < moviesGridTabletSize)
            return vm.initialItemsMobile;
        else if (deviceWidth < moviesGridDesktopSize)
            return vm.initialItemsTablet;
        else
            return vm.initialItemsDesktop;
    }

    function getShowMoreNumItemsToDisplay() {
        var deviceWidth = window.innerWidth;
        if (deviceWidth < moviesGridTabletSize)
            return vm.showMoreItemsMobile;
        else if (deviceWidth < moviesGridDesktopSize)
            return vm.showMoreItemsTablet;
        else
            return vm.showMoreItemsDesktop;
    }

    function getItemsPerRow() {
        switch (vm.screenType) {
            case 2:
                return vm.itemsPerRowDesktop;
            case 1:
                return vm.itemsPerRowTablet;
            case 0:
                return vm.itemsPerRowMobile;
            default:
                return vm.itemsPerRowMobile;
        }
    }

    function getMovies(numToRetrieve, numToSkip, uri, successCallback) {
        vm.loadingMore = true;
        vm.noMoviesFound = false;
        vm.requestedFilter = vm.activeFilter.serviceRequestString;
        var requestObject = { take: numToRetrieve, skip: numToSkip, language: vm.language };
        if (vm.activeFilter.serviceRequestString) {
            requestObject.movieFormat = vm.activeFilter.serviceRequestString;
        }
        vm.serviceRequestFunction(requestObject, vm.theatreId).then(successCallback, showError);
    }

    function initMoviesRequestSuccess(resp) {
        vm.moviesGrid.init(resp.data, (vm.initialitems + 1), resp.totalCount, [[0, vm.itemsPerRowMobile], [moviesGridTabletSize, vm.itemsPerRowTablet], [moviesGridDesktopSize, vm.itemsPerRowDesktop]]);
        vm.screenType = getScreenType();
        vm.gridClass = getGridClass();
        if (resp.data.length === 0) {
            vm.noMoviesFound = true;
        }
        vm.notInitialized = false;
        vm.loadingMore = false;
        $timeout(function () { $scope.$apply(); });
    }

    function windowResizeRequestSuccess(resp) {
        vm.moviesGrid.addItems(resp.data);
        vm.moviesGrid.init(vm.moviesGrid.allItems, (vm.initialitems + 1), resp.totalCount, [[0, vm.itemsPerRowMobile], [moviesGridTabletSize, vm.itemsPerRowTablet], [moviesGridDesktopSize, vm.itemsPerRowDesktop]]);
        vm.loadingMore = false;
        $timeout(function () { $scope.$apply(); });
    }

    function filterChangeRequestSuccess(resp) {
        vm.moviesGrid.init(resp.data, (vm.initialitems + 1), resp.totalCount, [[0, vm.itemsPerRowMobile], [moviesGridTabletSize, vm.itemsPerRowTablet], [moviesGridDesktopSize, vm.itemsPerRowDesktop]]);
        updateGridDisplay();
        if (resp.data.length === 0) {
            vm.noMoviesFound = true;
        }
        vm.loadingMore = false;
        $timeout(function () { $scope.$apply(); });
    }

    function moviesRequestSuccess(resp) {
        vm.moviesGrid.addItems(resp.data);
        vm.loadingMore = false;
        $timeout(function () { $scope.$apply(); });
    }

    function showMoreMovies() {
        if (vm.moviesGrid.showMoreOptionVisible) {
            getMovies(getShowMoreNumItemsToDisplay(), vm.moviesGrid.allItems.length, vm.requestUri, moviesRequestSuccess);
        }
    }

    function getGridClass() {
        return "col-xs-" + ("" + 12 / getItemsPerRow()).replace(".", "point");
    }

    function showError() {
        vm.error = true;
        $timeout(function () { $scope.$apply(); });
    } 
}