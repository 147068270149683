angular.module("stringLimitFilter", [])
.filter("stringLimit", function () {
    return function (string, param) {
        if (typeof (string) === "string") {
            if (string.length > param) {
                return string.slice(0, (param - 3)) + "...";
            }
            else
                return string;
        }
        else
            return string;
    };
});