(function () {
    angular.module("storeMoviesGridComponent", ["moviesStoreServiceModule", "trailerModalComponent", "loadMoreGridFactory", "stringLimitFilter", "safeHtmlFilter", "ui.bootstrap", "ImageLazyLoadDirective"])
    .component("storeMoviesGrid",
    {
        templateUrl: "/Scripts/app/movies-grid/store-movies-grid.template.2.html",
        bindings: {
            storeRentBuyText: "@",
            showMoreHtml: "@",
            seeMoreText: "@",
            errormessage: "@",
            noMoviesMessage: "@",
            stringLimitMobile: "<",
            stringLimitTablet: "<",
            stringLimitDesktop: "<",
            initialItemsMobile: "<",
            initialItemsTablet: "<",
            initialItemsDesktop: "<",
            showMoreItemsMobile: "<",
            showMoreItemsTablet: "<",
            showMoreItemsDesktop: "<",
            itemsPerRowMobile: "<",
            itemsPerRowTablet: "<",
            itemsPerRowDesktop: "<",
            accessibilityHints: "<",
            language: "@",
        },
        controller: ["moviesStoreService", "loadMoreGrid", "$scope", "$element", "$timeout", storeMoviesGridController]
    }
    );

    function storeMoviesGridController(moviesService, moviesGrid, $scope, $element, $timeout) {

        var vm = this;

        //Hard coded settings to satisfy base controller but specific to this module
        vm.serviceName = "getFeaturedStoreItems";
        vm.isOpen = true;
        vm.activeFilter = "";
        vm.trackClick = trackClick;

        function trackClick(region, event) {
            trackClickEvent(region, event);
        }

        new moviesGridControllerBase(moviesService, moviesGrid, $scope, $element, $timeout, vm);

        return vm;
    }

})();