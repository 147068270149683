(function () {

    angular
        .module('ImageLazyLoadDirective', [])
        .directive('lazyLoad', function () {
            var directive = {
                link: link,
                restrict: 'EA'
            };
            return directive;

            function link(scope, element, attrs) {
                var temp = [].slice.call(element[0].children);
                CINEPLEX.ImageLazyLoader.ConfigureImagesForLazyLoad(temp);
            }
        });

})();