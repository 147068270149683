angular.module("connectServiceModule", [])
.service("connectService", ["$http", "$q", function ($http, $q) {

    var self = this;

    self.addTheatreFavourites = function (locationIds, userId) {

        var requestObject = {
            LocationIds: locationIds,
            User: {
                UserProfileGuid: userId
            }
        }

        var deferredRequest = $q.defer();

        $http({
            method: 'post',
            url: '/ConnectUtils/AddFavourites',
            responseType: 'json',
            data: JSON.stringify(requestObject)
        }).then(
            requestSuccess,
            requestFailure
               );

        return deferredRequest.promise;

        function requestSuccess(resp) {
            deferredRequest.resolve(resp);
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

    self.removeTheatreFavourites = function (locationId, userId) {


        var requestObject = {
            LocationId: locationId,
            User: {
                UserProfileGuid: userId
            }
        }

        var deferredRequest = $q.defer();

        $http({
            method: 'post',
            url: '/ConnectUtils/RemoveFavourites',
            responseType: 'json',
            data: JSON.stringify(requestObject)
        }).then(
            requestSuccess,
            requestFailure
               );

        return deferredRequest.promise;

        function requestSuccess(resp) {
            deferredRequest.resolve(resp);
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

}]);