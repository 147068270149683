(function () {

    angular.module("ScrollToDirective", [])
    .directive('scrollToElem', function () {
        return {
            restrict: 'A',
            scope: {
                scrollTo: "@"
            },
            link: scrollToLinker
        };
    });

    function scrollToLinker($scope, $elem, $attr) {
        var scrollToElem;
        if ($attr.hasOwnProperty("href")) {
            scrollToElem = $attr.href;
        }
        else {
            scrollToElem = $scope.scrollTo;
        }

        //Note that jquery used to allow for animation of scroll
        $elem.on('click', function (event) {
            event.preventDefault();
            $('html,body').animate({ scrollTop: $(scrollToElem).offset().top }, "slow");
        });  
    }
})();