(function () {
    angular.module("eventMoviesGridComponent", ["moviesServiceModule", "trailerModalComponent", "loadMoreGridFactory", "stringLimitFilter", "safeHtmlFilter", "ui.bootstrap", "ImageLazyLoadDirective"])
        .component("eventMoviesGrid",
            {
                templateUrl: "/Scripts/app/movies-grid/event-movies-grid.template.1.html",
                bindings: {
                    releaseDateText: "@",
                    trailerText: "@",
                    showtimesText: "@",
                    advanceTicketsText: "@",
                    formatText: "@",
                    showMoreHtml: "@",
                    seeMoreText: "@",
                    errormessage: "@",
                    noMoviesMessage: "@",
                    movieLinkUrlParameters: "@",
                    stringLimitMobile: "<",
                    stringLimitTablet: "<",
                    stringLimitDesktop: "<",
                    initialItemsMobile: "<",
                    initialItemsTablet: "<",
                    initialItemsDesktop: "<",
                    showMoreItemsMobile: "<",
                    showMoreItemsTablet: "<",
                    showMoreItemsDesktop: "<",
                    itemsPerRowMobile: "<",
                    itemsPerRowTablet: "<",
                    itemsPerRowDesktop: "<",
                    theatreId: "<",
                    theatreUrlName: "@",
                    advertisementContent: "@",
                    playerElemId: "@",
                    brightCoveAccount: "@",
                    playerId: "@",
                    language: "@",
                    serviceName: "@"
                },
                controller: ["moviesService", "loadMoreGrid", "$uibModal", "$scope", "$element", "$timeout", eventMoviesGridController]
            }
        );

    function eventMoviesGridController(moviesService, moviesGrid, $uibModal, $scope, $element, $timeout) {

        var vm = this;
        vm.isOpen = true;
        vm.activeFilter = "";

        new moviesGridControllerBase(moviesService, moviesGrid, $scope, $element, $timeout, vm);

        vm.openModal = getBrightCoveVideoCategory;

        vm.generateMovieDetailsLink = generateMoviesDetailsLink;

        vm.generateShowtimesLink = generateShowTimesLink;

        vm.showtimeTextVisible = showtimeTextVisible;

        vm.advanceTicketsVisible = advanceTicketsVisible;

        //vm.displayReleaseDate = displayReleaseDate;

        vm.formatReleaseDate = formatReleaseDate;

        vm.displayTrailer = displayTrailer;

        function getBrightCoveVideoCategory(brightCoveId, cineplexVideoId, languageCode) {
            moviesService.getBrightCoveVideoDetails(brightCoveId, cineplexVideoId, languageCode).then(getCatagorySuccess, showError);
        };

        function getCatagorySuccess(response) {
            if (response.length !== 0) {
                var detailsObj = response;

                openTrailerModal(detailsObj.brightCoveId, detailsObj.cineplexVideoId, detailsObj.category);
            }
            else {
                //do something here
            }
            $timeout(function () { $scope.$apply(); });
        }



        function openTrailerModal(brightCoveId, cineplexVideoId, category) {
            var modalInstance = $uibModal.open({
                template: '<a href class="fa fa-close trailer-close"></a><trailer-component play-trigger="$ctrl.modalOpen" run-on-init="$ctrl.trailerModalRunOnInit" advertisement-content="$ctrl.advertisementContent" player-elem-id="$ctrl.playerElemId" bright-cove-account="$ctrl.brightCoveAccount" video-id="$ctrl.selectedVideoId" player-id="$ctrl.playerId" cineplex-video-id="$ctrl.cineplexVideoId"></trailer-component>',
                component: "trailerModal",
                windowClass: "trailer-modal",
                resolve: {
                    advertisementContent: function () {
                        return vm.advertisementContent;
                    },
                    playerElemId: function () {
                        return vm.playerElemId;
                    },
                    brightCoveAccount: function () {
                        return vm.brightCoveAccount;
                    },
                    videoId: function () {
                        return brightCoveId;
                    },
                    playerId: function () {
                        return vm.playerId;
                    },
                    cineplexVideoId: function () {
                        return cineplexVideoId;
                    },
                    category: function () {
                        return category;
                    }
                }
            });

            //required for boostrap modal to function properly
            modalInstance.result.then(
                function (item) {

                },
                function () {

                }
            );
        }

        function generateMoviesDetailsLink(movieName, langCode) {
            var urlPath = langCode.toLowerCase() === "fr" ? "/film/" : "/Movie/";
            return urlPath + movieName + constructUrlLanguageCode(vm.movieLinkUrlParameters, "?");
        }

        function generateShowTimesLink(movieName, showDate, langCode) {

            var nearbyTheatreString = "nearby-theatres",
                urlPath = "/Showtimes/",
                filterExtension = "";

            if (vm.activeFilter) {
                filterExtension = vm.activeFilter.showtimesFilterString ? ("&Type=" + vm.activeFilter.showtimesFilterString) : "";
            }

            if (vm.language === "fr-ca") {
                urlPath = "/Horaires/";
                nearbyTheatreString = "cinemas-les-plus-pres";
            }

            var location = vm.theatreUrlName.length > 0 ? vm.theatreUrlName : nearbyTheatreString;

            var momentShowDate = moment(showDate);
            return urlPath + movieName + "/" + location + "?Date=" + momentShowDate.format("M/D/YYYY") + filterExtension + constructUrlLanguageCode(vm.movieLinkUrlParameters, "&");
        }

        function showtimeTextVisible(isNowPlaying, hasShowtimes) {
            return isNowPlaying && hasShowtimes;
        }

        function advanceTicketsVisible(isNowPlaying, isComingSoon, hasShowtimes) {
            return !isNowPlaying && isComingSoon && hasShowtimes;
        }

        function displayTrailer(id, marketLangCode) {
            return id > 0;
        }

        function formatReleaseDate(date) {
            var releaseDate = moment(date);
            if (vm.language === "fr-ca") {
                releaseDate.locale('fr');
                return releaseDate.format("ddd D MMM YYYY");
            }

            return releaseDate.format("ddd, MMM D, YYYY");
        }

        function constructUrlLanguageCode(langCode, prefix) {
            if (langCode != null && langCode !== "") {
                return prefix + langCode;
            }
            return "";
        }

        function showError() {
            vm.showLoader = false;
            vm.error = true;
            $timeout(function () { $scope.$apply(); });
        }

        return vm;
    }

})();