(function () {
    angular.module("showtimeDetailsTableComponent", ["showtimesFunctionsService", "showtimesServiceModule"])
        .component("showtimeDetailsTable",
            {
                templateUrl: "/Scripts/app/showtime-details-table/showtime-details-table.template.html",
                bindings: {
                    movieId: "<",
                    theatresByDate: "@",
                    todayText: "@",
                    tomorrowText: "@",
                    reclinerText: "@",
                    runtimeText: "@",
                    trailerText: "@",
                    soldOutText: "@",
                    atText: "@",
                    noShowtimesText: "@",
                    selectTheatrePlaceholder: "@",
                    selectedPlaceholder: "@",
                    favouriteTheatresDropdownHeading: "@",
                    previouslySearchedTheatresDropdownHeading: "@",
                    localTheatresDropdownHeading: "@",
                    allTheatresDropdownHeading: "@",
                    noTheatreFound: "@",
                    errorText: "@",
                    languageCode: "@",
                    locationId: "@",
                    novieId: "@",
                    date: "@",
                    buyTicketsText: "@",
                    viewSeatMapText: "@"
                },
                controller: ["showtimesFunctions", "showtimesService", "$scope", "$timeout", showtimeDetailsTableController]
            }
        );

    function showtimeDetailsTableController(showtimesFunctions, showtimesService, $scope, $timeout) {

        var vm = this;

        vm.$onInit = initFunction;

        vm.enableNewExperienceBanners = js.settings.EnableNewExperienceBanners ? (js.settings.EnableNewExperienceBanners.toLowerCase() == 'true') : false;

        vm.newExperienceBannerPath = js.settings.ExperienceBannerSourcePath;

        vm.generateSpriteClasses = showtimesFunctions.generateSpriteClasses;

        vm.generateShowtimeMetaContent = showtimesFunctions.generateShowtimeMetaContent;

        vm.createIdForShowtime = showtimesFunctions.createIdForShowtime;

        vm.createShowtimeText = showtimesFunctions.createShowtimeText;

        vm.showtimeClicked = showtimesFunctions.showtimeClicked;

        vm.showtimeVisible = showtimesFunctions.showtimeVisible;

        vm.showtimeSeatSelectionVisible = showtimesFunctions.showtimeSeatSelectionVisible;

        vm.openShowtimeSeatMap = showtimesFunctions.openShowtimeSeatMap;

        vm.noTicketsAvailable = showtimesFunctions.noTicketsAvailable;

        vm.alignChildren = alignChildren;

        vm.trackClick = trackClick;

        function initFunction() {
            vm.showLoader = true;
            vm.noDates = false;
            vm.noShowtimes = false;
            initWindowResizeBehaviours();
            $scope.$watch(function () {
                return;
            }, updateShowtimes);
        }

        function initWindowResizeBehaviours() {
            $(window).on("resize", function () {
                alignChildren();
            });
        }

        function updateShowtimes() {
            vm.showLoader = true;
            showtimesService.getShowtimes(vm.locationId, vm.novieId, vm.date, vm.languageCode).then(getShowtimesSuccess, showError);
        }

        function getShowtimesSuccess(response) {
            if (response.length > 0) {
                vm.showtimeArray = response;
            }
            else {
                showError();
            }
        }

        function showError() {
            vm.showLoader = false;
            vm.error = true;
            $timeout(function () { $scope.$apply(); });
        }

        function trackClick(targetText) {
            trackCustomClickEvent(targetText);
        }

        function scopeApply() {
            $timeout(function () { $scope.$apply(); });
        }

        function alignChildren() {
            $timeout(function () {
                $(".movie-showtimes-table-wrapper .showtime--list").each(function (index, item) {
                    var showtimeContainer = $(item),
                        elements = showtimeContainer.children(),
                        firstElement = $(elements[0]),
                        elementWidth = firstElement.width(),
                        containerWidth = showtimeContainer.width(),
                        childrenPerRow = Math.floor(containerWidth / elementWidth),
                        paddingRoom = Math.floor((containerWidth - childrenPerRow * elementWidth) / (childrenPerRow - 1) / 2),
                        currentChild;

                    if (paddingRoom < 2) {
                        childrenPerRow--;
                        paddingRoom = Math.floor((containerWidth - childrenPerRow * elementWidth) / (childrenPerRow - 1) / 2);
                    }

                    if (paddingRoom > 5) {
                        paddingRoom = 5;
                    }

                    var paddingPx = paddingRoom.toString() + "px";

                    elements.each(function (index, child) {
                        currentChild = $(child);
                        currentChild.css("padding-right", "0px");
                        currentChild.css("padding-left", "0px");
                        if (index % childrenPerRow === 0) {
                            currentChild.css("padding-right", paddingPx);
                        }
                        else if ((index + 1) % childrenPerRow === 0) {
                            currentChild.css("padding-left", paddingPx);
                        }
                        else {
                            currentChild.css("padding-left", paddingPx);
                            currentChild.css("padding-right", paddingPx);
                        }
                    });
                });
                vm.showLoader = false;
                scopeApply();
            });
        }

    }
})();