(function () {
    angular.module("royalSliderComponent", ["safeHtmlFilter"])
    .component("royalSlider", {
        templateUrl: "/Scripts/app/shared/components/royal-slider.template.html",
        bindings: {
            classes: "@",
            settings: "<",
            slidesJson: "@"
        },
        controller: ["$element", royalSliderComponentController]
    });

    function royalSliderComponentController($element) {
        var vm = this;

        vm.$onInit = initComponent;
        vm.finishedLoading = false;
        vm.initSlider = initSlider;

        $(window).load(function () {
            // update size of slider (if you already initialized it before)
            $('.royalSlider').royalSlider('updateSliderSize', true);
        });

        function initComponent() {
            vm.slides = JSON.parse(vm.slidesJson);
        }
        
        function initSlider() {
            $element.find(".royalSlider").royalSlider(vm.settings);
            vm.finishedLoading = true;
            
            //accessibility logic for slider bullets
            var sliderBullets = $("button.rsBullet");

            //set aria labels on the slider bullets for tab controlling
            var slides = decodeURIComponent(vm.slides).split(',');
            for (var i = 0; i < slides.length; i++) {
                var altText = $(slides[i]).find('img').attr("alt");
                $(sliderBullets[i]).attr("aria-label", altText);
            }
            
            //when the user tabs to the next bullet, pull up its slide
            sliderBullets.focus(function (e) {
                $(e.currentTarget).click();
            });

            //provide a way for the user to "click" on the slide
            sliderBullets.keypress(function (e) {
                //check if they pressed space or enter on the bullet
                var key = e.which;
                if (key === 13 || key === 32) {
                    var slideIndex = sliderBullets.index(e.currentTarget);
                    $(".rsSlide a")[slideIndex].click();
                }
            });
        }
    }
})();