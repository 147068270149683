angular.module("promoServiceModule", [])
.service("promoService", ["$http", "$q", function ($http, $q) {

    var self = this;

    self.validatePromoCode = function (promoCode) {

        var deferredRequest = $q.defer();
        var requestObject = {
                promoCode: promoCode
            };

        $http.post("/Promo/ValidateMovieCashPromoCode", requestObject).then(requestSuccess, requestFailure);

        return deferredRequest.promise;

        function requestSuccess(resp) {
            deferredRequest.resolve(mapResponse(resp.data));

        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

    self.submitGeneralMillsCoupon = function (coupon) {
        if (coupon != null) {
            var deferredRequest = $q.defer();

            $http.post("/GeneralMills/SubmitCouponRequest", coupon).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                deferredRequest.resolve(resp.data);
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }
        }
    };

    self.submitNflPromo = function (eventId, email, emailConfirmation) {
        var deferredRequest = $q.defer();
        var postObj = {
            EventId: eventId,
            Email: email,
            EmailConfirmation: emailConfirmation
        }

        $http.post("/NFL/SubmitNFLEvent", postObj).then(requestSuccess, requestFailure);

        return deferredRequest.promise;

        function requestSuccess(resp) {
            deferredRequest.resolve(resp);
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }

    }

    function mapResponse(response) {
        var respObj = JSON.parse(response);

        return {
            status: respObj.Status,
            theatresAllowed: respObj.LocationAllowed,
            daysAllowed: respObj.DaysAllowed,
            isAllLocationsAllowed: respObj.IsAllLocationsAllowed,
            isAllDayAllowed: respObj.IsAllDayAllowed
        };
    }        

    }]);