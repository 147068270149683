angular.module("showtimesFunctionsService", [])
.service("showtimesFunctions", function () {

    var showtimesServiceObject = {};

    showtimesServiceObject.generateSpriteClasses = generateSpriteClasses;

    showtimesServiceObject.generateShowtimeMetaContent = generateShowtimeMetaContent;

    showtimesServiceObject.createIdForShowtime = createIdForShowtime;

    showtimesServiceObject.createShowtimeText = createShowtimeText;

    showtimesServiceObject.showtimeVisible = showtimeVisible;

    showtimesServiceObject.showtimeSeatSelectionVisible = showtimeSeatSelectionVisible;

    showtimesServiceObject.openShowtimeSeatMap = openShowtimeSeatMap;

    showtimesServiceObject.noTicketsAvailable = noTicketsAvailable;

    showtimesServiceObject.showtimeClicked = showtimeClicked;

    showtimesServiceObject.constructSeriesTicketingLink = constructSeriesTicketingLink;

    return showtimesServiceObject;

    function generateSpriteClasses(spriteClass) {
        return "movie-cat-wrap banner-sprite sprite-" + spriteClass;
    }

    function generateShowtimeMetaContent(startTimeUtc) {
        return moment.utc(startTimeUtc).format('h:mm a');
    }

    function createIdForShowtime(LocationId, VistaSessionId, AreaCode) {
        return 'l_' + parseInt(LocationId) + '_s_' + parseInt(VistaSessionId) + '_c_' + AreaCode;
    }

    function createShowtimeText(startTime, languageCode) {
        var text = languageCode !== "en-us" ? moment.utc(startTime).format('H [h] mm') : moment.utc(startTime).format('h:mm a');
        return text;
    }

    function formatExperience(experience) {
        if (!experience) return 'na';
        return experience
            .replace(/,/g, ' ')
            .replace('cineplex clubhouse', 'Clubhouse')
            .replace('stars and strollers', 'Stars & Strollers')
            .replace('regular', 'Regular')
            .replace('3d', '3D')
            .replace('ultraavx', 'UltraAVX')
            .replace(/vip [\d]*\+/, 'VIP')
            .replace('atmos', 'Atmos')
            .replace('dbox', 'D-BOX')
            .replace('imax', 'IMAX')
            .replace('recliner', 'Recliner')
            .replace('event', 'Event')
            .replace('4dx', '4DX')
            .replace('prime', 'Prime Seats');
    }

    function showtimeClicked(e, isShowtimeEnabled, showtimeUrl, experience) {
        var filmExperience = formatExperience(experience)

        if (!isShowtimeEnabled)
            e.preventDefault();
        else if (showtimeUrl !== null && showtimeUrl !== "") {
            var oldConnectCloseFunction;
            if (!Cineplex.Connect.IsUserLoggedIn()) {
                e.preventDefault();
                oldConnectCloseFunction = Cineplex.Connect.Settings.Close;
                Cineplex.Connect.Settings.Close = function () {
                    oldConnectCloseFunction();                    
                    if (Cineplex.Connect.IsUserLoggedIn()) {                        
                        window.location = showtimeUrl;
                    }
                    Cineplex.Connect.Settings.Close = oldConnectCloseFunction;
                };
                Cineplex.Connect.OpenConnectPage(1);
            } else
                window.location = showtimeUrl;
        }

        adobeDataLayer.push({
            content: {
                experience: filmExperience
            }
        });
    }

    function showtimeVisible(isSoldOut, inThePast) {
        return !isSoldOut && !inThePast;
    }

    function showtimeSeatSelectionVisible(isSoldOut, inThePast, isReservedSeating, isShowtimeEnabled) {
        return ((!isSoldOut && !inThePast) && isReservedSeating && isShowtimeEnabled);
    }

    function openShowtimeSeatMap(locationId, seatmapUrl) {
        var seatMapFrame = $('.seatMapWindowUrl');
        seatMapFrame.attr("src", seatmapUrl);
        //set the window location to fix a caching issue
        seatMapFrame[0].contentWindow.location.href = seatmapUrl;

        $.remodal.lookup[$('[data-remodal-id=seatmap]').data('remodal')].open();
    }

    function noTicketsAvailable() {
        $.remodal.lookup[$('[data-remodal-id=showtime-na-modal]').data('remodal')].open();
    }

    function constructSeriesTicketingLink(baseUrl, theatreId) {
        return baseUrl + theatreId;
    }
});