angular.module('connectFunctionsFactory', [])
.factory('connectFunctions', function () {
    var factoryObj = {};
    
    factoryObj.executeOnConnectInitiziation = executeOnConnectInitiziation;
    
    factoryObj.getConnectGuidAndSessToken = getConnectGuidAndSessToken;

    return factoryObj;

    function executeOnConnectInitiziation(callback) {
        var oldConnectInitializedCallback = window.connectInitializedCallback || function () { return; };
        window.connectInitializedCallback = function () {
            oldConnectInitializedCallback();
            callback();
        };
    }

    function getConnectGuidAndSessToken() {
        var result = {
            successful: false,
            connectGUID: "",
            ccToken: ""
        };

        if (Cineplex != null && Cineplex.Connect != null && typeof Cineplex.Connect.IsUserLoggedIn === "function" && Cineplex.Connect.IsUserLoggedIn()) {
            //TODO: Add general functions to angular general functions factory
            var ccTokenCookie = CINEPLEX.generalFunctions.getCookieValue("CCToken");
            if (ccTokenCookie.found) {
                var userInfo = Cineplex.Connect.GetUserStatus();
                result.connectGUID = userInfo.UserProfileGuid;
                result.ccToken = ccTokenCookie.value;
                if (result.connectGUID && result.ccToken) {
                    result.successful = true;
                }
            }
        }

        return result;
    }

});