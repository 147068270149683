(function () {
    angular.module("addTheatreToFavouritesButtonComponent", ["connectServiceModule"])
    .component("favouriteTheatre",
    {
        templateUrl: "/Scripts/app/favourite-theatre-button/add-theatre-to-favourites-button.template.html",
        bindings: {
            theatreId: "<",
            addToFavouritesText: "@",
            addedToFavouritesText: "@",
            serviceFailureMessage: "@",
            tooltipMessage: "@"
        },
        controller: ["connectService", "$scope", "$timeout", "$element", addTheatreToFavouritesController]
    });

    function addTheatreToFavouritesController(connectService, $scope, $timeout, $element) {

        var vm = this;
        vm.connectInitializationAttempt = 0;
        vm.theatreFavourited = false;
        vm.errorMessage = "";

        vm.$onInit = initFunction;
        vm.toggleFavouriteTheatre = toggleFavouriteTheatre;
        
        function initFunction() {
            initializeConnectData();
        }

        function toggleFavouriteTheatre() {
            if (Cineplex.Connect.IsUserLoggedIn()) {
                if (vm.theatreFavourited) {
                    removeFavouriteTheatre(vm.theatreId);
                }
                else {
                    addFavouriteTheatre(vm.theatreId);
                }
            }
            else {
                Cineplex.Connect.OpenConnectPage(1);
            }
        }

        //Necessary code used to determine connect login status. (Timeouts used to 
        function initializeConnectData() {
            var oldConnectInitializedCallback = window.connectInitializedCallback || function () { return; };
            window.connectInitializedCallback = function () {
                oldConnectInitializedCallback();
                if (Cineplex.Connect.IsUserLoggedIn()) {
                    Cineplex.Connect.GetFavouriteTheatres(connectGetFavouriteTheatresSuccessCallback, connectGetFavouriteTheatresErrorCallback);
                }
                else {
                    var oldConnectCloseFunction = Cineplex.Connect.Settings.Close;
                    Cineplex.Connect.Settings.Close = function () {
                        oldConnectCloseFunction();
                        if (Cineplex.Connect.IsUserLoggedIn()) {
                            Cineplex.Connect.GetFavouriteTheatres(connectGetFavouriteTheatresSuccessCallback);
                        }
                        else {
                            vm.theatreFavourited = false;
                        }
                    };
                }
            };
        }

        function connectGetFavouriteTheatresSuccessCallback(connectObj) {
            connectObj.Theatres.forEach(function (theatre) {
                if (theatre.LocationId === vm.theatreId)
                    vm.theatreFavourited = true;
            });
            $timeout(function () {
                $scope.$apply();
            });
        }

        function connectGetFavouriteTheatresErrorCallback() {

        }

        function addFavouriteTheatre(theatreId) {
            var addTheatreId = [theatreId],
                userProfileGuid = Cineplex.Connect.GetUserStatus().UserProfileGuid || "";
            connectService.addTheatreFavourites(addTheatreId, userProfileGuid).then(addFavouriteTheatreSuccess, requestFailure);
        }

        function removeFavouriteTheatre(theatreId) {
            var userProfileGuid = Cineplex.Connect.GetUserStatus().UserProfileGuid || "";
            connectService.removeTheatreFavourites(theatreId, userProfileGuid).then(removeFavouriteTheatreSuccess, requestFailure);
        }

        //TODO ROSS: ADD SPECIFIC ERROR MESSAGES FOR USER
        function addFavouriteTheatreSuccess(resp) {
            if (resp.data[0].Status === 1) {
                vm.theatreFavourited = true;
                $timeout(function () { $scope.$apply(); });
            }
            else {
                showErrorMessage(vm.serviceFailureMessage);
            }
        }

        function removeFavouriteTheatreSuccess(resp) {
            vm.theatreFavourited = false;
            $timeout(function () { $scope.$apply(); });
        }

        function requestFailure(resp) {
            showErrorMessage(vm.serviceFailureMessage);
        }

        function showErrorMessage(message) {
            vm.errorMessage = message;
            $timeout(function () { $scope.$apply(); });
            $element.find(".cineplex-error-message").fadeIn(300).delay(2000).fadeOut(300);
        }

    }
})();