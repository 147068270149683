angular.module("showtimesServiceModule", [])
    .service("showtimesService", ["$http", "$q", function ($http, $q) {

    var self = this;

        self.getShowtimes = function (locationId, filmId, date, languageCode) {
            var showtimeApiUrl = js.settings.ShowtimeOnePosterApiUrl;
            var oldShowtimeApiUrl = js.settings.OldShowtimeOnePosterApiUrl;
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + showtimeApiUrl : oldShowtimeApiUrl) + "?", requestObject;
        if (languageCode == null || languageCode == "") {
            languageCode = "en-us";
        }
        requestObject = {
            locationId: locationId,
            filmId: filmId,
            date: date,
            language: languageCode
        };

        requestUri += $.param(requestObject);

        var deferredRequest = $q.defer();


            $http({ 
				url: requestUri, 
				method: "GET",
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                    }
                }).then(requestSuccess, requestFailure);



        return deferredRequest.promise;

        function requestSuccess(resp) {
            var mappedData;
            try {
                mappedData = mapShowtimeResponse(resp.data);
                deferredRequest.resolve(mappedData);
            }
            catch (error) {
                deferredRequest.reject(error);
            }
        }

        function requestFailure(resp) {
            deferredRequest.reject(resp);
        }
    };

    function mapShowtimeResponse(resp) {

        var mappedResponse = [];

        resp.data.forEach(function (showtimesExperience) {
            mappedResponse.push(new showtimesExperienceObject(showtimesExperience));
        });

        return mappedResponse;
    }

    function showtimesExperienceObject(configObject) {
        var resultObject = {
            experienceType: configObject.experienceType,
            presentationType: configObject.presentationType,
            attributeID: configObject.attributeID,
            movieExperienceImageClass: configObject.movieExperienceImageName,
            experienceBannerAltText: configObject.experienceBannerAltText,
            movieExperienceTitle: configObject.experienceType,
            presentationTypeTitle: configObject.presentationTypeTitle,
            areaCode: configObject.areaCode,
            showtimes: []
        };

        configObject.showtimes.forEach(function (showtime) {
            resultObject.showtimes.push(new showtimesObject(showtime));
        });

        return resultObject;
    }

    function showtimesObject(configObject) {
        this.showStartDateTimeUtc = configObject.showStartDateTimeUtc;
        this.isReservedSeating = configObject.isReservedSeating;
        this.isShowtimeEnabled = configObject.isShowtimeEnabled;
        this.seatMapUrl = configObject.seatMapUrl;
        this.ticketingUrl = configObject.ticketingUrl
        this.vistaSessionId = configObject.vistaSessionId;
        this.isSoldOut = configObject.isSoldOut;
        this.inThePast = configObject.inThePast;
        this.isOnlineTicketingAvailable = configObject.isOnlineTicketingAvailable;
    }

}]);