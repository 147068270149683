angular.module("sliderModalComponent", [])
.component("sliderModal", (function () {
    return {
        templateUrl: "/Scripts/app/shared/components/royal-slider-modal.template.html",
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        controller: ["$document", sliderModalComponentController]
    };

    function sliderModalComponentController($document) {

        var vm = this;

        vm.$onInit = initComponent;
        vm.finishedLoading = false;
        vm.initSlider = initSlider;

        function initComponent() {
            vm.slides = vm.resolve.slidesJson;
            vm.classes = vm.resolve.classes;
        }

        function initSlider() {
            $document.find("#royal-slider-modal-container .royalSlider").imagesLoaded(function (inst) {
               $(inst.elements[0]).royalSlider(vm.resolve.settings);
            });
            vm.finishedLoading = true;
        }

    }

})());