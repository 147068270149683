(function () {
    angular.module("moviesGridControlsComponent", ["moviesGridComponent", "ui.bootstrap"])
    .component("moviesGridControls",
    {
        templateUrl: "/Scripts/app/movies-grid/movies-grid-controls.template.1.html",
        bindings: {
            releaseDateText: "@",
            trailerText: "@",
            showtimesText: "@",
            advanceTicketsText: "@",
            formatText: "@",
            showMoreHtml: "@",
            seeMoreText: "@",
            errormessage: "@",
            noMoviesMessage: "@",
            advertisementContent: "@",
            playerElemId: "@",
            brightCoveAccount: "@",
            playerId: "@",
            language: "@",
            theatreUrlName: "@",
            movieLinkUrlParameters: "@",
            stringLimit: "<",
            showMoreItemsMobile: "<",
            showMoreItemsTablet: "<",
            showMoreItemsDesktop: "<",
            initialItemsMobile: "<",
            initialItemsTablet: "<",
            initialItemsDesktop: "<",
            itemsPerRowMobile: "<",
            itemsPerRowTablet: "<",
            itemsPerRowDesktop: "<",
            stringLimitMobile: "<",
            stringLimitTablet: "<",
            stringLimitDesktop: "<",
            tabs: "<",
            filters: "<",
            theatreId: "<",
            compactDisplay: "<"
        },
        controller: featuredMoviesGridController
    }
    );

    function featuredMoviesGridController() {
        var vm = this;
        vm.filters = [];
        vm.activeFilter = "";
        vm.activeTabIndex = 0;

        vm.$onInit = onInit;
        vm.toggleFilter = toggleFilter;
        vm.isTabActive = isTabActive;
        vm.tabChanged = tabChanged;
        vm.filterText = filterText;
        vm.trackClick = trackClick;

        function onInit() {
            vm.filters = generateFilterObjects(vm.filters);
            vm.filtersVisible = vm.filters.length > 0 ? vm.filters[vm.activeTabIndex].hasFilters : false;
        }

        function generateFilterObjects(filterStrings) {
            var filterObjArray = [];
            filterStrings.forEach(function (filter) {
                filterObjArray.push(new filterObject(filter));
            });
            return filterObjArray;
        }

        function toggleFilter(filter) {
            setActiveFilter(filter);
        }

        function isTabActive(tabIndex) {
            return tabIndex === vm.activeTabIndex;
        }

        function tabChanged(tab) {
            setActiveFilter("");
            vm.filtersVisible = tab.hasFilters;

        }

        function setActiveFilter(activeFilter) {
            var noFilter = true;
            vm.filters.forEach(function (filter) {
                if (filter === activeFilter) {
                    noFilter = false;
                    filter.enabled = !filter.enabled;
                    setFilterActive(filter);
                }
                else {
                    filter.enabled = false;
                }
            });
            if (noFilter)
                vm.activeFilter = {name: "", serviceRequestString: "", showtimesFilterString: ""};
        }

        function setFilterActive(filter) {
            if (filter.enabled) vm.activeFilter = filter;
            else vm.activeFilter = {name: "", serviceRequestString: "", showtimesFilterString: ""};
        }

        function filterText(filterName) {
            return filterName.name === "" ? vm.formatText : vm.activeFilter.name;
        }

        function filterObject(initObj) {
            this.name = initObj.filterText;
            this.serviceRequestString = initObj.serviceRequestString;
            this.showtimesFilterString = initObj.showtimesFilterString;
            this.active = false;
            return this;
        }

        function trackClick(region, event) {
            trackClickEvent(region, event);
        }

    }
})();