(function () {
    angular.module("theatreShowtimesTableComponent", ["showtimesFunctionsService", "moviesServiceModule", "ui.bootstrap", "ImageLazyLoadDirective"])
    .component("theatreShowtimesTable",
    {
        templateUrl: "/Scripts/app/theatre-showtimes-table/theatre-showtimes-table.template.v5.html",
        bindings: {
            theatreId: "<",
            availableDatesJson: "@",
            todayText: "@",
            tomorrowText: "@",
            reclinerText: "@",
            runtimeText: "@",
            trailerText: "@",
            soldOutText: "@",
            noShowtimesText: "@",
            languageCode: "@",
            advertisementContent: "@",
            playerElemId: "@",
            brightCoveAccount: "@",
            playerId: "@",
            agilityCommonBuyTickets: "@",
            agilityViewSeatMap: "@",
            showtimeNotAvailable: "@",
            jsonData: "@",
            isMissingAttrEnabled: "<"
        },
        controller: ["showtimesFunctions", "moviesService", "$uibModal", "$scope", "$timeout", theatreShowtimesTableController]
    }
    );

    function theatreShowtimesTableController(showtimesFunctions, moviesService, $uibModal, $scope, $timeout) {

        var vm = this;

        vm.$onInit = initFunction;

        vm.enableNewExperienceBanners = js.settings.EnableNewExperienceBanners ? (js.settings.EnableNewExperienceBanners.toLowerCase() == 'true') : false;

        vm.newExperienceBannerPath = js.settings.ExperienceBannerSourcePath;

        vm.selectDate = selectDate;

        vm.displayTrailer = displayTrailer;

        vm.keyboardSupportOpenModal = keyboardSupportOpenModal;

        vm.openTrailerModal = getBrightCoveVideoCategory;

        vm.alignChildren = alignChildren;

        vm.generateSpriteClasses = showtimesFunctions.generateSpriteClasses;

        vm.generateShowtimeMetaContent = showtimesFunctions.generateShowtimeMetaContent;

        vm.createIdForShowtime = showtimesFunctions.createIdForShowtime;

        vm.createShowtimeText = showtimesFunctions.createShowtimeText;

        vm.showtimeClicked = showtimesFunctions.showtimeClicked;

        vm.showtimeVisible = showtimesFunctions.showtimeVisible;

        vm.showtimeSeatSelectionVisible = showtimesFunctions.showtimeSeatSelectionVisible;

        vm.openShowtimeSeatMap = showtimesFunctions.openShowtimeSeatMap;

        vm.noTicketsAvailable = showtimesFunctions.noTicketsAvailable;

        vm.trackClick = trackClick;

        function initFunction() {
            vm.showLoader = true;
            vm.noDates = false;
            vm.noShowtimes = false;
            vm.dateOptions = formatDateResults(JSON.parse(vm.availableDatesJson));
            vm.selectedDate = vm.dateOptions[0];
            vm.showtimeArray = mapTheatreShowtimesData(JSON.parse(vm.jsonData));
            initResizeFunctionality();
        }

        function initResizeFunctionality() {
            $(window).on("resize", alignChildren);
        }

        function trackClick(targetText) {
            trackCustomClickEvent(targetText);
        }

        function formatDateResults(dates) {
            if (dates.length !== 0) {
                var movieDates = [];
                dates.forEach(function (dateItem) {
                    var utcTime = moment.utc(dateItem),
                        localTime = moment(),
                        tomorrowTime = moment().add(1, 'days'),
                        dateItemFormatted,
                        localTimeFormatted;

                    if (vm.languageCode === "fr-ca") {
                        utcTime.locale('fr');
                        localTime.locale('fr');
                        tomorrowTime.locale('fr');
                        dateItemFormatted = utcTime.format("ddd D MMM YYYY");
                        localTimeFormatted = localTime.format("ddd D MMM YYYY");
                    }
                    else {
                        dateItemFormatted = utcTime.format("ddd, MMM DD YYYY");
                        localTimeFormatted = localTime.format("ddd, MMM DD YYYY");
                    }

                    if (dateItemFormatted === localTimeFormatted) {
                        var todayDisplayText = vm.todayText + " (" + dateItemFormatted + ")";
                        movieDates.push(new theatreShowtimesDate(todayDisplayText, dateItemFormatted, utcTime));
                    }
                    else {
                        movieDates.push(new theatreShowtimesDate(dateItemFormatted, dateItemFormatted, utcTime));
                    }
                });
                return movieDates;
            }
            else {
                vm.noDates = true;
                vm.showLoader = false;
                vm.noShowtimes = true;
                return [];
            }
        }

        function mapTheatreShowtimesData(theatreShowtimesData) {
            if (theatreShowtimesData.length !== 0) {
                var mappedDataArray = [];
                theatreShowtimesData.forEach(function (movieData) {
                    mappedDataArray.push(new theatreMovieObject(movieData));
                });
                return mappedDataArray;
            }
            else {
                vm.showLoader = false;
                vm.noShowtimes = true;
                return [];
            }
        }

        function theatreMovieObject(configObject) {
            this.brightcoveVideoId = configObject.brightcoveVideoId;
            this.duration = configObject.duration;
            this.filmId = configObject.filmId;
            this.filmName = configObject.filmName;
            this.mpaaRating = configObject.mpaaRating;
            this.passRestriction = getPassRestrictions(configObject.showTimeDetails);
            this.filmRuntime = configObject.filmRuntime;
            this.largePosterImageUrl = configObject.largePosterImageUrl;
            this.mediumPosterImageUrl = configObject.mediumPosterImageUrl;
            this.showTimeDetails = mapExperienceTypes(configObject.showTimeDetails);
            this.marketLanguageCode = configObject.marketLanguageCode;
        }

        function getPassRestrictions(theatreShowtimesData) {
            for (var i = 0; i < theatreShowtimesData.length; i++) {
                if (theatreShowtimesData[i].hasPassRestriction) {
                    return new noPassObject(theatreShowtimesData[i]);
                }
            }
            return;
        }

        function noPassObject(configObject) {
            this.hasPassRestriction = configObject.hasPassRestriction;
            this.noPassText = configObject.noPassText;
            this.noPassDescription = configObject.noPassDescription;
        }

        function mapExperienceTypes(theatreShowtimesData) {
            var mappedDataArray = [];
            theatreShowtimesData.forEach(function (experienceType) {
                mappedDataArray.push(new experienceTypeObject(experienceType));
            });
            return mappedDataArray;
        }

        function experienceTypeObject(configObject) {
            this.experienceType = configObject.experienceType;
            this.attributeID = configObject.attributeID;
            this.areaCode = configObject.areaCode;
            this.movieExperienceImageClass = configObject.movieExperienceImageClass;
            this.experienceBannerAltText = configObject.experienceBannerAltText;
            this.movieExperienceTitle = configObject.movieExperienceTitle;
            this.order = configObject.order;
            this.presentationType = configObject.presentationType;
            this.presentationTypeTitle = configObject.presentationTypeTitle;
            this.showtimes = mapShowtimeObjects(configObject.showtimes);
        }

        function mapShowtimeObjects(theatreShowtimesData) {
            var mappedDataArray = [];
            var tomorrowTimeMoment = moment().add(1, 'days'),
            tomorrowTime = tomorrowTimeMoment.format("DD");
            theatreShowtimesData.forEach(function (showtime) {
                var newShowtime = new showtimeObject(showtime);
                if (tomorrowTime === moment.utc(newShowtime.showStartDateTimeUtc).format("DD")) {
                    newShowtime.midnightShowtime = true;
                    if (vm.languageCode === "fr-ca") {
                        tomorrowTimeMoment.locale('fr');
                    }
                    newShowtime.midnightShowtimeDay = tomorrowTimeMoment.format("ddd");
                }
                else {
                    newShowtime.midnightShowtime = false;
                    newShowtime.midnightShowtimeDay = "";
                }
                mappedDataArray.push(newShowtime);
            });
            return mappedDataArray;
        }

        function showtimeObject(configObject) {
            this.showStartDateTimeUtc = configObject.showStartDateTimeUtc;
            this.isReservedSeating = configObject.isReservedSeating;
            this.isShowtimeEnabled = configObject.isShowtimeEnabled;
            this.seatMapUrl = configObject.seatMapTicketingLink;
            this.ticketingUrl = configObject.ticketingUrl
            this.vistaSessionId = configObject.vistaSessionId;
            this.isSoldOut = configObject.isSoldOut;
            this.inThePast = configObject.inThePast;
            this.isOnlineTicketingAvailable = configObject.isOnlineTicketingAvailable;
        }

        function displayTrailer(id, marketLangCode) {
            return id > 0;
        }

        function getBrightCoveVideoCategory(brightCoveId, cineplexVideoId, languageCode) {
            moviesService.getBrightCoveVideoDetails(brightCoveId, cineplexVideoId, languageCode).then(getCatagorySuccess, showError);
        };

        function getCatagorySuccess(response) {
            if (response.length !== 0) {
                var detailsObj = response;

                openTrailerModal(detailsObj.brightCoveId, detailsObj.cineplexVideoId, detailsObj.category);
            }
            else {
                //do something here
            }
            $timeout(function () { $scope.$apply(); });
        }

        function keyboardSupportOpenModal(brightCoveId, cineplexVideoId, languageCode, event) {
            if (event.code == 'Enter' || event.which == 13 || event.code == 'Space' || event.which == 32) {
                event.preventDefault();
                getBrightCoveVideoCategory(brightCoveId, cineplexVideoId, languageCode);
            }
        }

        function openTrailerModal(brightCoveId, cineplexVideoId, category) {
            var modalInstance = $uibModal.open({
                template: '<a href class="fa fa-close trailer-close"></a><trailer-component play-trigger="$ctrl.modalOpen" run-on-init="$ctrl.trailerModalRunOnInit" advertisement-content="$ctrl.advertisementContent" player-elem-id="$ctrl.playerElemId" bright-cove-account="$ctrl.brightCoveAccount" video-id="$ctrl.selectedVideoId" player-id="$ctrl.playerId" cineplex-video-id="$ctrl.cineplexVideoId"></trailer-component>',
                component: "trailerModal",
                windowClass: "trailer-modal",
                resolve: {
                    advertisementContent: function () {
                        return vm.advertisementContent;
                    },
                    playerElemId: function () {
                        return vm.playerElemId;
                    },
                    brightCoveAccount: function () {
                        return vm.brightCoveAccount;
                    },
                    videoId: function () {
                        return brightCoveId;
                    },
                    playerId: function () {
                        return vm.playerId;
                    },
                    cineplexVideoId: function () {
                        return cineplexVideoId;
                    },
                    category: function () {
                        return category;
                    }
                }
            });

            //required for boostrap modal to function properly
            modalInstance.result.then(
                function (item) {

                },
                function () {

                }
            );
        }

        function alignChildren() {
            $timeout(function () {
                $(".theatre-showtime-selection-wrapper .showtime--list").each(function (index, item) {
                    var showtimeContainer = $(item),
                        elements = showtimeContainer.children(),
                        firstElement = $(elements[0]),
                        elementWidth = firstElement.width(),
                        containerWidth = showtimeContainer.width(),
                        childrenPerRow = Math.floor(containerWidth / elementWidth),
                        paddingRoom = Math.floor((containerWidth - childrenPerRow * elementWidth) / (childrenPerRow - 1) / 2),
                        currentChild;

                    if (paddingRoom < 2) {
                        childrenPerRow--;
                        paddingRoom = Math.floor((containerWidth - childrenPerRow * elementWidth) / (childrenPerRow - 1) / 2);
                    }

                    if (paddingRoom > 5) {
                        paddingRoom = 5;
                    }

                    var paddingPx = paddingRoom.toString() + "px";
                    elements.each(function (index, child) {
                        currentChild = $(child);
                        currentChild.css("padding-right", "0px");
                        currentChild.css("padding-left", "0px");
                        if (index % childrenPerRow === 0) {
                            currentChild.css("padding-right", paddingPx);
                        }
                        else if ((index + 1) % childrenPerRow === 0) {
                            currentChild.css("padding-left", paddingPx);
                        }
                        else {
                            currentChild.css("padding-left", paddingPx);
                            currentChild.css("padding-right", paddingPx);
                        }
                    });
                });
                $scope.$broadcast("rebuildQtScrollbar");
                vm.showLoader = false;
                $scope.$apply();
            });
        }

        function selectDate(theatreShowtimesDateObject) {
            var dateRequestString = theatreShowtimesDateObject.momentDateObject.format("YYYY-MM-DD");
            vm.selectedDate = theatreShowtimesDateObject;
            vm.showLoader = true;
            vm.noShowtimes = false;
            moviesService.getTheatreMoviesWithShowtimesByDate(vm.theatreId, dateRequestString, vm.languageCode).then(getTheatreMoviesSuccess, showError);
            $timeout(function () { $scope.$apply(); });
        }

        function getTheatreMoviesSuccess(response) {
            if (response.length !== 0) {
                vm.showtimeArray = response;
            }
            else {
                vm.showLoader = false;
                vm.noShowtimes = true;
            }
            $timeout(function () { $scope.$apply(); });
        }

        function theatreShowtimesDate(displayText, formattedText, momentDateObject) {
            this.displayText = displayText;
            this.formattedText = formattedText;
            this.momentDateObject = momentDateObject;
        }

        function showError() {
            vm.showLoader = false;
            vm.error = true;
            $timeout(function () { $scope.$apply(); });
        }
    }
})();