angular.module("blogsComponent", ["safeHtmlFilter"])
.component("blogs",
{
    templateUrl: "/Scripts/app/blogs/blogs.template.html",
    bindings: {
        jsonData: "<",
        languageData: "<"
    },
    controller: ["$scope", "$sce", "safeHtmlFilter", blogsController]
});

function blogsController(scope, sce) {

    var self = this;

    self.$onInit = initFunction;
    self.bindHtml = deliberatelyTrustHtml;
    self.getUrl = getUrl;

    function initFunction() {
        self.allBlogs = self.jsonData;
        setBelogs(self.allBlogs);
        self.language = self.languageData;
    }

    function setBelogs(allBlogs) {
        if (allBlogs != null && allBlogs !== undefined && allBlogs.length > 0) {
            self.firstBlog = allBlogs[0];
            if (allBlogs.length > 1) {
                allBlogs.splice(0, 1);
                self.blogs = allBlogs;
            }
        }
    }

    function deliberatelyTrustHtml(htmlValue) {
        return sce.trustAsHtml(htmlValue);
    }

    function getUrl(prefix, url) {
        if (prefix != null && prefix !== undefined
            && url != null && url !== undefined) {
            return prefix + "/" + url;
        } else return "";
    }
}