(function () {
    angular.module("nflPromoComponent", ["safeHtmlFilter"])
    .component("nflPromo", {
        templateUrl: "/Scripts/app/promotions/NFL/nflPromo.template.html",
        bindings: {
            language: "@",
            availablePromosHeading: "@",
            pastPromosHeading: "@",
            comingPromosHeading: "@",
            comingSoonPromosText: "@",
            promoOptions: "@",
            addEmailCtaHtml: "@",
            emailLabelText: "@",
            emailPlaceholderText: "@",
            verifyEmailLabelText: "@",
            verifyEmailPlaceholderText: "@",
            fieldRequiredText: "@",
            emailRequiredText: "@",
            invalidEmailText: "@",
            emailsMustMatchText: "@",
            submitText: "@",
            promoEndedText: "@",
            promoEndsText: "@",
            promoStartsText: "@",
            selectedPromoHeading: "@",
            selectedPromoExpiresBy: "@",
            selectPromoHtml: "@",
            promoSelectionHeadingHtml: "@",
            submissionSuccessfulHtml: "@",
            unexpectedErrorHtml: "@",
            unexpectedErrorFormText: "@",
            voucherNotFoundText: "@",
            promoExpiredText: "@",
            emailErrorText: "@",
            isAutoSelected: "@",
            promosComingSoonHtml: "@",
            promosPastHtml: "@",
            selectedIndicatorHtml: "@",
            showtimesLinkConstructor: "@"
        },
        controller: ['$scope', '$timeout', 'promoService', nflPromoController]
    });

    function nflPromoController($scope, $timeout, promoService) {
        var vm = this;

        vm.emailInput = "";
        vm.confirmEmailInput = "";

        vm.$onInit = init;

        vm.selectPromo = selectPromo;

        vm.submitEmail = submitEmail;

        vm.emailsMatch = emailsMatch;



        function init() {
            var now = moment.utc();

            vm.loading = false;
            vm.promoSuccess = false;
            vm.promosComing = false;
            vm.promosPast = false;

            vm.emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

            vm.promoOptions = initializePromos(vm.promoOptions);

            //Sorting By Times
            var availablePromosUnsorted = vm.promoOptions.filter(function (promo) {
                return now.isBefore(promo.promoEnd) && now.isAfter(promo.promoStart) && promo.isAvailable;
            });
            var pastPromosUnsorted = vm.promoOptions.filter(function (promo) {
                return now.isAfter(promo.promoEnd);
            });
            var promoOptionsUnsorted = vm.promoOptions.filter(function (promo) {
                return now.isBefore(promo.promoStart);
            });

            //Sorting By When Available
            vm.availablePromos = availablePromosUnsorted.sort(sortPromos);
            vm.pastPromos = pastPromosUnsorted.sort(sortPromos);
            vm.comingPromos = promoOptionsUnsorted.sort(sortPromos);

            if (vm.promoAutoSelected) {
                vm.selectedPromo = vm.availablePromos.length ? vm.availablePromos[0] : null;
            }

            if (vm.availablePromos.length === 0 && vm.comingPromos.length > 0) {
                vm.promosComing = true;
            }
            else if (vm.availablePromos.length === 0 && vm.pastPromos.length > 0) {
                vm.promosPast = true;
            }

            vm.promosFinished = (vm.availablePromos.length + vm.comingPromos.length) === 0;


        }

        function initializePromos(promoOptions) {
            var promos = JSON.parse(promoOptions);
            var mappedPromos = [];

            promos.forEach(function (promo) {
                mappedPromos.push(new nflPromo(promo));
            });

            return mappedPromos;
        }

        function nflPromo(initObject) {
            this.promoName = initObject.eventName;
            this.promoId = initObject.eventId;
            this.promoImg = initObject.eventImageUrl;
            this.promoStart = initObject.effectiveDate;
            this.promoEnd = initObject.playDate;
            this.eventSlug = initObject.eventSlug;
            this.isAvailable = initObject.isAvailable;
            this.promoStartFormatted = formatTimeString(this.promoStart);
            this.promoEndFormatted = formatTimeString(this.promoEnd);
        }

        function sortPromos(promo1, promo2) {
            var promo1Start = moment(promo1.promoStart);
            var promo2Start = moment(promo2.promoStart);
            return promo1Start.isAfter(promo2Start);
        }

        function formatTimeString(date) {
            var releaseDate = moment.utc(date);
            if (vm.language === "fr-ca") {
                releaseDate.locale('fr');
                return releaseDate.format("ddd D MMM YYYY");
            }
            return releaseDate.format("ddd, MMM D, YYYY");
        }

        function selectPromo(promo) {
            if (!vm.promoSuccess && !vm.loading) {
                vm.selectedPromo = promo;
            }
        }

        function submitEmail() {
            if (emailsMatch() && $scope.nflForm.$valid) {
                vm.formError = false;
                vm.loading = true;
                promoService.submitNflPromo(vm.selectedPromo.promoId, vm.emailInput, vm.confirmEmailInput).then(promoSuccess, promoFail);
            }
        }

        function emailsMatch() {
            return vm.emailInput && vm.confirmEmailInput && vm.emailInput.toLowerCase() === vm.confirmEmailInput.toLowerCase();
        }

        function promoSuccess(resp) {
            switch (resp.data.Status) {
                case 1:
                    vm.promoSuccess = true;
                    vm.submissionSuccessfulHtml = vm.submissionSuccessfulHtml.replace("{0}", generateShowTimesLink(vm.selectedPromo.eventSlug, vm.selectedPromo.promoEnd));
                    break;
                case 2:
                    vm.formErrorMessage = vm.emailErrorText;
                    break;
                case 3:
                    vm.formErrorMessage = vm.voucherNotFoundText;
                    break;
                case 4:
                    vm.formErrorMessage = vm.promoExpiredText;
                    break;
                default:
                    vm.formErrorMessage = vm.unexpectedErrorFormText;
                    break;
            }
            vm.formError = true;
            vm.loading = false;
        }

        function promoFail(err) {
            vm.formError = true;
            vm.formErrorMessage = vm.unexpectedErrorFormText;
            vm.loading = false;
        }

        function generateShowTimesLink(movieName, showDate) {

            var nearbyTheatreString = "nearby-theatres",
                urlPath = "/Showtimes",
                anyMovieString = "any-movie";

            if (vm.language === "fr-ca") {
                urlPath = "/Horaires";
                nearbyTheatreString = "cinemas-les-plus-pres";
                anyMovieString = "n-importe-quel-film";
            }

            var momentShowDate = moment(showDate);
            var resultPath = movieName ? urlPath + "/" + movieName + "/" + nearbyTheatreString + "?Date=" + momentShowDate.format("M/D/YYYY") : urlPath + "/" + anyMovieString + "/" + nearbyTheatreString + "?Date=" + momentShowDate.format("M/D/YYYY");

            return resultPath;
        }

    }

})();