angular.module("moviesStoreServiceModule", [])

    .service("moviesStoreService", ["$http", "$q", function ($http, $q) {

        var self = this;

        self.getFeaturedStoreItems = function (requestObject) {
            var requestUri = js.settings.TheatricalApiUrl + "store/featuredmovies?";
            requestUri += $.param(requestObject);
            var request = {
                headers: {
                    'Ocp-Apim-Subscription-Key': js.settings.TheatricalApiSubscriptionKey
                }
            }

            var deferredRequest = $q.defer();

            $http.get(requestUri, request).then(success, error);

            return deferredRequest.promise;

            function success(resp) {
                try {
                    var result = mapResponse(resp);
                    deferredRequest.resolve(result);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function error(resp) {
                deferredRequest.reject(resp);
            }

        };


        function mapResponse(resp) {
            var moviesArray = [];
            resp.data.items.forEach(function (movieItem) {
              
                moviesArray.push(new movieObject(movieItem.title, movieItem.imageUrl, movieItem.linkUrl, movieItem.rentOrBuyLinkText));
            }); 
            return new responseObject(moviesArray, resp.data.totalCount);
        }

        function responseObject(movies, totalCount) {
            return {
                "data": movies,
                "totalCount": totalCount
            };
        }

        function movieObject(title, imageUrl, linkUrl, rentOrBuyLinkText) {
            return {
                "title": title,
                "imageUrl": imageUrl,
                "linkUrl": linkUrl,
                "rentOrBuyLinkText": rentOrBuyLinkText
            };
        }


        return self;

    }]);