(function () {
    angular.module("popoverController", ["ui.bootstrap"])
    .controller("popoverController", ["$scope", popoverControllerFunction]);
    function popoverControllerFunction($scope) {
        $scope.popover = {

            isOpen: false,

            open: function open() {
                $scope.popover.isOpen = true;
            },

            close: function close() {
                $scope.popover.isOpen = false;
            }
        };
    }
})();