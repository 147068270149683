(function () {
    angular.module("conditionalShowItemsComponent", ["safeHtmlFilter"])
        .component("conditionalShowItems", {
            bindings: {
                itemSeperatorHtml: "@",
                lastSeparatorHtml: "@",
                showItemsHtml: "@",
                items: "<",
                itemsShownXs: "<?",
                itemsShownSm: "<?",
                itemsShownMd: "<?",
                itemsShownLg: "<?"
            },
            templateUrl: "/Scripts/app/shared/components/conditionallyShowItems.template.html",
            controller: ["$scope", "$timeout", "cineplexConstants", conditionalShowController]
        });

    function conditionalShowController($scope, $timeout, cineplexConstants) {
        var vm = this;

        vm.$onInit = initFunction;
        vm.contentShown = false;
        vm.visibleItems = [];
        vm.resizeDebounce = null;
        vm.showContent = showContent;

        function initFunction() {
            checkSizeDisplay();
        };

        function windowResize() {
            clearTimeout(vm.resizeDebounce);
            vm.resizeDebounce = timeout(function () {
                if (!vm.contentShown) {
                    checkSizeDisplay();
                }
            }, 300);
        }

        //TODO: Add sizes to constants file
        function checkSizeDisplay() {
            if (window.innerWidth < cineplexConstants.smBreakpoint && vm.itemsShownXs != null) {
                setItemsIfLess(vm.itemsShownXs);
            }
            else if (window.innerWidth < cineplexConstants.mdBreakpoint && vm.itemsShownSm != null) {
                setItemsIfLess(vm.itemsShownSm);
            }
            else if (window.innerWidth < cineplexConstants.lgBreakpoint && vm.itemsShownMd != null) {
                setItemsIfLess(vm.itemsShownMd);
            }
            else if (vm.itemsShownLg != null) {
                setItemsIfLess(vm.itemsShownLg);
            }
            else {
                showContent();
            }
            $timeout(function () { $scope.$apply(); });

        }

        function setItemsIfLess(numItems) {
            if (numItems < vm.items.length) {
                vm.visibleItems = vm.items.slice(0, numItems);
            }
            else {
                showContent();
            }
        }

        function showContent() {
            vm.contentShown = true;
            vm.visibleItems = ([]).concat(vm.items);
            //$scope.$apply();
        }

    }
})();