(function () {
    angular.module("selectizeInputComponent", [])
    .component("selectizeInput", {
        templateUrl: "/Scripts/app/shared/components/selectize-input.template.html",
        bindings: {
            settings: "<",
            options: "<",
            selectedOption: "=",
            selectizeDisabled: "=?",
            onSelect: "&"
        },
        controller: ["$scope", "$element", "$timeout", selectizeComponentController]
    });

    function selectizeComponentController($scope, $element, $timeout) {
        var vm = this;
        vm.$onInit = initFunction;

        function initFunction() {

            vm.inputElem = $element.find("select");
            vm.originalPlaceholder = vm.settings.placeholder;
            vm.keyingThrough = false;

            var settings = { options: vm.options };

            $.extend(settings, vm.settings);

            vm.inputElem.selectize(settings);

            vm.inputElem.data("selectize").on('change', function () {
                vm.selectedOption = vm.inputElem.data("selectize").getValue();
                $timeout(function () { $scope.$apply(); });
            });
            $element.find('input').attr('autocomplete', 'donotfill');

            //announce highlighted option for accessibility when textbox receives focus
            $element.on('focusin', function () {
                var selectedValue = $element.find('.option.active').text() //prioritize the highlighted option in the dropdown
                    || $element.find('select.selectized option').text() //then the previously selected option if we're just tabbing in
                    || getFirstOptionInDropdown(); //or the first item if none selected
                $element.find('input').attr('aria-label', selectedValue);
                vm.keyingThrough = false;
            });

            $element.on('focusout', function () {
                if (!vm.keyingThrough) {
                    resetPlaceholder();
                }
            });

            //announce hightlighted option for accessibility as the user keys through the options list
            $element.on('keydown', function (e) {
                //user is up/down arrow keying through options
                if (e.keyCode == 38 || e.keyCode == 40) {
                    vm.keyingThrough = true;
                    var selectedOption = $element.find('.option.active');
                    vm.inputElem.data("selectize").settings.placeholder = selectedOption.text();
                    vm.inputElem.data("selectize").updatePlaceholder();
                    vm.inputElem.data("selectize").setValue(selectedOption.attr('data-value'), true);
                    //re-trigger the input focus to refresh the aria-label
                    $element
                        .find('input')
                        .addClass('temporary-placeholder')
                        .attr('aria-label', selectedOption.text())
                        .blur().focus();
                }
            });

            $scope.$watchCollection(function () { return vm.options; }, optionsUpdate);
            
            $scope.$watch(function () { return vm.selectedOption; }, selectedUpdated);

            initDisabledWatch(vm.selectizeDisabled);

        }

        function getFirstOptionInDropdown() {
            var selectOptions = [];
            //find first element in dropdown by default
            if (!vm.settings.optGroupOrder) {
                selectOptions = vm.options;
            } else {
                //dropdown has subgroups, check them in order to find first valid option
                vm.settings.optGroupOrder.some(function (filter) {
                    //check if this subgroup has any options
                    selectOptions = vm.options.filter(function (option) { return option.type === filter });
                    return selectOptions.length > 0;
                });

                //subgroups didn't match any options for some reason, return them all by default
                if (selectOptions.length <= 0) {
                    selectOptions = vm.options;
                }
            }

            return selectOptions[0].text;
        }

        function optionsUpdate() {
            vm.inputElem.data("selectize").clearOptions();
            vm.inputElem.data("selectize").addOption(vm.options);
        }

        function selectedUpdated() {
            if (vm.selectedOption !== vm.inputElem.data("selectize").getValue()) {
                vm.inputElem.data("selectize").setValue(vm.selectedOption);
            }
            resetPlaceholder();
        }

        function resetPlaceholder() {
            vm.inputElem.data("selectize").settings.placeholder = vm.originalPlaceholder;
            vm.inputElem.data("selectize").updatePlaceholder();
            $element.find('input').removeClass('temporary-placeholder');
        }

        function initDisabledWatch(disabledSetting){
            if(disabledSetting != null)
                $scope.$watch(function () { return vm.selectizeDisabled; }, enabledUpdate);
        }

        function enabledUpdate() {
            if (!vm.selectizeDisabled) {
                vm.inputElem.data("selectize").enable();
            }
            else {
                vm.inputElem.data("selectize").disable();
                vm.inputElem.data("selectize").setValue("");
            }
        }

    }
})();