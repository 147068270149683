(function(){
    angular.module("modalComponent", ["ui.bootstrap"])
        .component("modalWindow",
        {
            templateUrl: "/Scripts/app/shared/components/modal-link.template.html",
            bindings: {
                jsonContent: "@",
                modalTemplate: "@",
                modalTemplateUrl: "@",
                componentName: "@",
                modalSettings: "<",
                modalLinkHtml: "@",
                modalLinkClass: "@"
            },
            controller: ["$uibModal", modalWindowController]
        });

    function modalWindowController($uibModal) {

        var self = this;

        self.$onInit = function () {

            var passedContent = JSON.parse(self.jsonContent);
            var parametersToPass = {};

            for (var attr in passedContent) {
                parametersToPass[attr] = getFun(passedContent[attr]);
            }

            //self.modalSettings = {
            //    windowClass: self.windowClass,
            //    resolve: parametersToPass
            //};

            self.uibSettings = self.modalSettings || {};

            self.uibSettings.resolve = parametersToPass;

            if (self.modalTemplate) {
                self.uibSettings.template = self.modalTemplate;
            }
            else if (self.modalTemplateUrl) {
                self.uibSettings.templateUrl = self.modalTemplateUrl;

            }
            else {
                self.uibSettings.component = self.componentName;
            }
        }

        self.openModal = openModal;
    
        function getFun(val) {
            return function () { return val; };
        }

        function openModal() {
            var modalInstance = $uibModal.open(self.uibSettings);

            //required for boostrap modal to function properly
            modalInstance.result.then(
                function (item) {},
                function () {});
        }
    }
})();