(function () {
    angular.module("movieShowtimesTableComponent", ["showtimesFunctionsService", "theatreFunctionsFactory", "showtimesServiceModule", "theatresServiceModule"])
    .component("movieShowtimesTable",
    {
        templateUrl: "/Scripts/app/movie-showtimes-table/movie-showtimes-table.template.v4.html",
        bindings: {
            movieId: "<",
            theatresByDate: "@",
            todayText: "@",
            tomorrowText: "@",
            reclinerText: "@",
            runtimeText: "@",
            trailerText: "@",
            soldOutText: "@",
            atText: "@",
            noShowtimesText: "@",
            selectTheatrePlaceholder: "@",
            selectedPlaceholder: "@",
            favouriteTheatresDropdownHeading: "@",
            previouslySearchedTheatresDropdownHeading: "@",
            localTheatresDropdownHeading: "@",
            allTheatresDropdownHeading: "@",
            noTheatreFound: "@",
            errorText: "@",
            languageCode: "@",
            buyTicketsText: "@",
            viewSeatMapText: "@"
        },
        controller: ["showtimesFunctions", "theatreFunctions", "theatresService", "showtimesService", "$scope", "$q", "$timeout", movieShowtimesTableController]
    }
    );

    function movieShowtimesTableController(showtimesFunctions, theatreFunctions, theatresService, showtimesService, $scope, $q, $timeout) {

        var vm = this;

        var tempTheatreOptions = [];

        vm.$onInit = initFunction;

        vm.selectDate = selectDate;

        vm.enableNewExperienceBanners = js.settings.EnableNewExperienceBanners ? (js.settings.EnableNewExperienceBanners.toLowerCase() == 'true') : false;

        vm.newExperienceBannerPath = js.settings.ExperienceBannerSourcePath;

        vm.generateSpriteClasses = showtimesFunctions.generateSpriteClasses;

        vm.generateShowtimeMetaContent = showtimesFunctions.generateShowtimeMetaContent;

        vm.createIdForShowtime = showtimesFunctions.createIdForShowtime;

        vm.createShowtimeText = showtimesFunctions.createShowtimeText;

        vm.showtimeClicked = showtimesFunctions.showtimeClicked;

        vm.showtimeVisible = showtimesFunctions.showtimeVisible;

        vm.showtimeSeatSelectionVisible = showtimesFunctions.showtimeSeatSelectionVisible;

        vm.openShowtimeSeatMap = showtimesFunctions.openShowtimeSeatMap;

        vm.noTicketsAvailable = showtimesFunctions.noTicketsAvailable;

        vm.alignChildren = alignChildren;

        vm.trackClick = trackClick;


        function initFunction() {
            vm.showLoader = true;
            vm.noDates = false;
            vm.noShowtimes = false;
            vm.noTheatreResults = false;
            vm.showSelectTheatreMessage = false;
            vm.selectedTheatre = "";
            vm.selectedDate = "";
            vm.theatreOptions = [];
            vm.theatreSelectizeOptions = {
                optgroups: [
    { id: 'fav', name: vm.favouriteTheatresDropdownHeading },
    { id: 'searched', name: vm.previouslySearchedTheatresDropdownHeading },
    { id: 'nearBy', name: vm.localTheatresDropdownHeading },
    { id: 'regular', name: vm.allTheatresDropdownHeading }
                ],
                placeholder: vm.selectTheatrePlaceholder,
                title: vm.selectTheatrePlaceholder,
                labelField: 'text',
                valueField: 'id',
                searchField: ['searchTerm'],
                optgroupField: 'type',
                optgroupLabelField: 'name',
                optgroupValueField: 'id',
                optGroupOrder: ['fav', 'searched', 'nearBy', 'regular'],
                lockOptgroupOrder: true,
                onDropdownOpen: selectizeAdjustOnOpen,
                onType: searchByTheatreNoResults,
                onBlur: searchByTheatreClearErrors,
                onDropdownClose: selectizeAdjustOnClose
            };
            populateInitData();
            updateTheatreTypes();
            initWindowResizeBehaviours();
            $scope.$watch(function () {
                return vm.selectedTheatre;
            }, updateShowtimes);
        }

        function populateInitData() {
            try {
                var theatresByDate = JSON.parse(vm.theatresByDate);
                if (theatresByDate != null && theatresByDate.length > 0) {
                    vm.dateOptions = formatData(theatresByDate);
                    vm.selectedDate = vm.dateOptions[0];
                    vm.theatreOptions = vm.dateOptions[0].theatres;
                }
                else {
                    throw "No Data Provided";
                }
            }
            catch (err) {
                vm.noDates = true;
                vm.showLoader = false;
                vm.noShowtimes = true;
                scopeApply();
            }
        }

        function initWindowResizeBehaviours() {
            $(window).on("resize", function () {
                alignChildren();
            });
        }

        function formatData(configObject) {
            this.movieDateTheatres = [];
            configObject.forEach(function (dateTheatreItem) {
                if (moment(dateTheatreItem.date).isSameOrAfter(moment.utc(moment().startOf('day')))) {
                    this.movieDateTheatres.push(new dateTheatreObject(formatTimeDisplay(dateTheatreItem.date), mapTheatreResults(dateTheatreItem.theatres)));}
            });
            return movieDateTheatres;
        }

        function formatTimeDisplay(timeString) {
            var utcTime = moment.utc(timeString),
            localTime = moment(),
            tomorrowTime = moment().add(1, 'days'),
            result = null,
            dateItemFormatted,
            localTimeFormatted,
            tomorrowFormatted;

            if (moment(timeString).isSameOrAfter(moment.utc(moment().startOf('day')))) {
                if (vm.languageCode === "fr-ca") {
                    utcTime.locale('fr');
                    localTime.locale('fr');
                    tomorrowTime.locale('fr');
                    dateItemFormatted = utcTime.format("ddd D MMM YYYY");
                    localTimeFormatted = localTime.format("ddd D MMM YYYY");
                    tomorrowFormatted = tomorrowTime.format("ddd D MMM YYYY");

                }
                else {
                    dateItemFormatted = utcTime.format("ddd, MMM DD YYYY");
                    localTimeFormatted = localTime.format("ddd, MMM DD YYYY");
                    tomorrowFormatted = tomorrowTime.format("ddd, MMM DD YYYY");
                }

                if (dateItemFormatted === localTimeFormatted) {
                    var todayDisplayText = vm.todayText;
                    //+ " (" + dateItemFormatted + ")";
                    result = new theatreShowtimesDate(todayDisplayText, dateItemFormatted, utcTime);
                }
                else if (dateItemFormatted === tomorrowFormatted) {
                    var tomorrowDisplayText = vm.tomorrowText;
                    //" (" + dateItemFormatted + ")";
                    result = new theatreShowtimesDate(tomorrowDisplayText, dateItemFormatted, utcTime);
                }
                else {
                    result = new theatreShowtimesDate(dateItemFormatted, dateItemFormatted, utcTime);
                }
                return result;
            }
        }

        function mapTheatreResults(theatres) {
            var theatresOptions = [];
            theatres.forEach(function (theatreItem) {
                theatresOptions.push(new theatreShowtimesObject(theatreItem.locationId, theatreItem.locationInteractiveName, theatreItem.isFavourite, "regular", theatreItem.city, theatreItem.province));
            });
            return theatresOptions;
        }

        function updateTheatreTypes() {
            if (vm.theatreOptions != null && vm.theatreOptions.length > 0) {
                theatresService.getLocalTheatres().then(updateTheatreTypesSuccess, showError);
            }
            else {
                showError();
            }
        }

        function updateTheatreTypesSuccess(response) {
            var theatreList = ([]).concat(vm.theatreOptions);
            theatreList = theatreFunctions.updateTheatreType(theatreList, response, "nearBy");
            theatreList = updateHistoricalTheatres(theatreList);
            vm.theatreOptions = theatreList;
                                                             
            var searchedTheatreList = theatreList.filter(function (obj) { return obj.type === "searched"; });
            var nearbyTheatreList = theatreList.filter(function (obj) { return obj.type === "nearBy"; });
         
            var selected = "";
            if (searchedTheatreList.length > 0) {
                selected = searchedTheatreList[0].id;
            } else if (nearbyTheatreList.length > 0) {
                selected = nearbyTheatreList[0].id;
            } else {
                selected = theatreList[0].id;
            }

            vm.selectedTheatre = selected + "";    
        }

        function theatreShowtimesObject(locationId, locationName, isFavourite, theatreType, city, province) {
            this.id = locationId;
            this.text = locationName;
            this.type = isFavourite ? "fav" : "regular";
            this.searchTerm = locationName + " " + city + " " + province;
        }

        function selectDate(theatreShowtimesDateObject) {
            var dateRequestString = theatreShowtimesDateObject.date.momentDateObject.format("YYYY-MM-DD");
            vm.selectedDate = theatreShowtimesDateObject;
            vm.showLoader = true;
            vm.noShowtimes = false;
            vm.selectedTheatre = "";
            vm.showtimeArray = [];
            vm.theatreOptions = theatreShowtimesDateObject.theatres;
            updateTheatreTypes();
            scopeApply();
        }

        function updateShowtimes(newSelectedTheatre, oldSelectedTheatre) {
            if (newSelectedTheatre && newSelectedTheatre !== oldSelectedTheatre) {
                vm.showLoader = true;
                var selectedTheatreObject = getSelectedItem(vm.theatreOptions, vm.selectedTheatre);
                showtimesService.getShowtimes(selectedTheatreObject.id, vm.movieId, vm.selectedDate.date.momentDateObject.format("YYYY-MM-DD"), vm.languageCode).then(getShowtimesSuccess, showError);
            }
            else {
                scopeApply();
            }
        }

        function getShowtimesSuccess(response) {
            if (response.length > 0) {
                vm.showtimeArray = response;
            }
            else {
                showError();
            }
        }

        function theatreShowtimesDate(displayText, formattedText, momentDateObject) {
            this.displayText = displayText;
            this.formattedText = formattedText;
            this.momentDateObject = momentDateObject;
        }

        function dateTheatreObject(dateObject, theatresArray) {
            this.date = dateObject;
            this.theatres = theatresArray;
        }

        function updateHistoricalTheatres(theatreList) {
            var searchedTheatres = theatreFunctions.getHistoricalTheatreSearches();
            var updatedTheatreList = theatreList;
            if (searchedTheatres !== null) {
                updatedTheatreList = theatreFunctions.updateHistoricalTheatreType(theatreList, searchedTheatres, "searched");
            }
            return updatedTheatreList;
        }

        function showError() {
            vm.showLoader = false;
            vm.error = true;
            $timeout(function () { $scope.$apply(); });
        }

        function selectizeAdjustOnOpen() {
            var self = this;
            vm.selectizePlaceholder = self.settings.placeholder;
            self.settings.placeholder = vm.selectedPlaceholder;
            self.updatePlaceholder();
            self.$wrapper.addClass("selectize-open");
            self.$wrapper.parent().addClass("selectize-open");
            self.$input.data("selectize").setActiveOption(self.$dropdown_content.find('[data-selectable]:first'));
            self.$input.data("selectize").clear(false);
            self.$dropdown_content.scrollTop(0);
            if ($(window).width() < 769) {
                $("body, html").animate({ scrollTop: (self.$wrapper.offset().top - 10) }, 500);
            }
        }

        function selectizeAdjustOnClose() {
            var self = this;
            self.$wrapper.removeClass("selectize-open");
            self.$wrapper.parent().removeClass("selectize-open");
            self.settings.placeholder = vm.selectizePlaceholder;
            self.updatePlaceholder();
        }

        function searchByTheatreNoResults() {
            var self = this;
            if (!self.hasOptions) {
                vm.noTheatreResults = true;
            }
            else {
                vm.noTheatreResults = false;
            }
            scopeApply();
        }

        function searchByTheatreClearErrors() {
            vm.noTheatreResults = false;
            scopeApply();
        }

        function scopeApply() {
            $timeout(function () { $scope.$apply(); });
        }

        function getSelectedItem(itemArray, itemId) {
            return itemArray.filter(function (item) { return item.id === parseInt(itemId); })[0];
        }

        function trackClick(targetText) {
            trackCustomClickEvent(targetText);
        }

        function alignChildren() {
            $timeout(function () {
                //var windowWidth = $(window).width();
                $(".movie-showtimes-table-wrapper .showtime--list").each(function (index, item) {
                    var showtimeContainer = $(item),
                        elements = showtimeContainer.children(),
                        firstElement = $(elements[0]),
                        elementWidth = firstElement.width(),
                        containerWidth = showtimeContainer.width(),
                        childrenPerRow = Math.floor(containerWidth / elementWidth),
                        paddingRoom = Math.floor((containerWidth - childrenPerRow * elementWidth) / (childrenPerRow - 1) / 2),
                        currentChild;

                    if (paddingRoom < 2) {
                        childrenPerRow--;
                        paddingRoom = Math.floor((containerWidth - childrenPerRow * elementWidth) / (childrenPerRow - 1) / 2);
                    }

                    if (paddingRoom > 5) {
                        paddingRoom = 5;
                    }

                    var paddingPx = paddingRoom.toString() + "px";

                    elements.each(function (index, child) {
                        currentChild = $(child);
                        currentChild.css("padding-right", "0px");
                        currentChild.css("padding-left", "0px");
                        if (index % childrenPerRow === 0) {
                            currentChild.css("padding-right", paddingPx);
                        }
                        else if ((index + 1) % childrenPerRow === 0) {
                            currentChild.css("padding-left", paddingPx);
                        }
                        else {
                            currentChild.css("padding-left", paddingPx);
                            currentChild.css("padding-right", paddingPx);
                        }
                    });
                });
                vm.showLoader = false;
                scopeApply();
            });
        }

    }
})();