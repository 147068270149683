(function () {
    angular.module("theatreFilmDescriptionComponent", ["ui.bootstrap", "ImageLazyLoadDirective"])
        .component("theatreFilmDescription",
            {
                templateUrl: "/Scripts/app/theatre-showtimes-table/theatre-film-description.template.html",
                bindings: {
                    movieName: '@',
                    movieMpaaRating: '@',
                    movieNoPass: '@'
                },
                controller: [theatreFilmDescriptionController]
            });

    function theatreFilmDescriptionController() {
        var vm = this;

        vm.$onInit = initFunction;

        function initFunction() {
            vm.mpaaRating = vm.movieMpaaRating ? JSON.parse(vm.movieMpaaRating) : vm.movieMpaaRating;
            vm.noPass = vm.movieNoPass ? JSON.parse(vm.movieNoPass) : vm.movieNoPass;

            // filmRating is from Action Controller and Repositories.Models.Film.FilmWarningRating
            // ratingTitle is from HTTP request to availablemovies/showtimesoneposter and Api.V1.Models.MovieRatingWarning
            // Only one should be populated while the other is null
            vm.rating = vm.mpaaRating? vm.mpaaRating.filmRating ? vm.mpaaRating.filmRating : vm.mpaaRating.ratingTitle ? vm.mpaaRating.ratingTitle : null : null;
        }
    }
})();