(function () {
    angular.module("movieRatingsAndWarningsComponent", ["ui.bootstrap", "safeHtmlFilter"])
    .component("movieRatingsAndWarningsModalWindow", {
        templateUrl: "/Scripts/app/movie-ratings-and-warnings/movie-ratings-and-warnings-modal.template.html",
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        controller: ["$scope", movieRatingsAndWarningsModalController]
    });

    function movieRatingsAndWarningsModalController($scope) {

        var self = this;

        self.$onInit = function () {
            self.title = self.resolve.title;
            self.modalContent = self.resolve.ratingsWarningsList;
            self.languageResource = self.resolve.languageResource;
            self.closeLabel = self.resolve.closeLabel;
        };

        //self.$onChanges = openRatingsAndWarningsModal;

        //function openRatingsAndWarningsModal() {
        //    self.modalContent = self.resolve;
        //    self.languageResource = self.resolve.languageResource;
        //}
    }
})();