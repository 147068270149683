angular.module("trailerModalComponent", [])
.component("trailerModal", (function () {
    return {
        templateUrl: "/Scripts/app/shared/components/trailer-modal.template.html",
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        controller: ["$document", trailerComponentController]
    };

    function trailerComponentController($document) {
        var self = this;
        self.$onInit = function () {
            startPlayer();
        };
        //Private Functions

        function startPlayer() {
            $document.find(".brightcove-video-container").append(constructVideoHtml(self.resolve.playerElemId, self.resolve.brightCoveAccount, self.resolve.videoId, self.resolve.playerId, self.resolve.category));
            initPlayer();
        }

        function constructVideoHtml(playerElemId, brightCoveAccount, videoId, playerId,category) {
            var element = "<video id='" + playerElemId + "' data-account='" + brightCoveAccount + "' data-video-id='" + videoId + "' category='" + category + "' data-player='" + playerId + "' data-embed = 'default' class='video-js brightcove-video' controls></video>";
            return element;
        }

        function initPlayer() {
            bc(document.getElementById(self.resolve.playerElemId));
            var brightcovePlayer = videojs(self.resolve.playerElemId);
            brightcovePlayer.overlay({
                overlays: [{
                    "content": "<div id='adMessage' class='adMessage'>" + self.resolve.advertisementContent + "</div> <p id='adTimeRemaining'><span id='timeTarget'></span></p>",
                    "align": "top",
                    "start": "adstart",
                    "end": "adend"
                }]
            });
            brightcovePlayer.on('loadedmetadata', function () {
                var theInterval,
                    timeLeftInAd;

                //Function to be called every second during ad playback
                //Calculates time remaining and injects into overlay
                function everySecond() {
                    var timeLeft = Math.floor(brightcovePlayer.ima3.adsManager.getRemainingTime()),
                        minsLeft = Math.floor(timeLeft / 60),
                        secsLeft = timeLeft % 60;
                    if (secsLeft < 10) secsLeft = "0" + secsLeft;
                    var timeElement = document.getElementById('timeTarget');
                    if (timeElement) {
                        timeElement.innerHTML = minsLeft + ":" + secsLeft;
                    }
                    else {
                        clearInterval(theInterval);
                    }
                }

                //On start of ads in pod
                brightcovePlayer.on('ads-pod-started', function () {
                    //Remove the hide class so overlay displays
                    brightcovePlayer.removeClass("hide-overlay");
                    //Start the counter that calls function every second
                    everySecond();
                    theInterval = setInterval(everySecond, 1000);
                });

                //On end of ads in pod
                brightcovePlayer.on('ads-pod-ended', function () {
                    //Stop the counter
                    clearInterval(theInterval);
                    //Hide the overlay
                    brightcovePlayer.addClass("hide-overlay");
                    //Clear any numbers so on display of overlay no small numbers left
                    document.getElementById('timeTarget').innerHTML = '';
                    sendMovioTracking();
                });

                //ADDED SO IF AD FAILS MOVIO REQUEST STILL SENT
                brightcovePlayer.on('ima3-ad-error', function () {
                    sendMovioTracking();
                });

                function sendMovioTracking() {
                    if (CINEPLEX && CINEPLEX.movioServices) {
                        CINEPLEX.movioServices.trackMovieTrailer(self.resolve.cineplexVideoId);
                    }
                }

            });
        }
    }

})());