(function () {
    angular.module("generalMillsPromoCouponModalComponent", ["promoServiceModule", "safeHtmlFilter"])
    .component("generalMillsPromoCouponModal", {
        templateUrl: "/Scripts/app/promotions/general-mills/general-mills-coupon-modal.template.html",
        bindings: {
            resolve: "<",
            close: "&",            
            dismiss: "&"
        },
        controller: ["$scope", "$timeout", "promoService", generalMillsPromoCouponLearnMoreModalController]
    });

    function generalMillsPromoCouponLearnMoreModalController($scope, $timeout, promoService) {

        var self = this;

        self.succeeded = false;
        self.failed = false;
        self.submitConfirmed = submitConfirmed;
        self.$onInit = function () {
            self.showLoader = false;
            self.title = self.resolve.title;
            self.modalContent = self.resolve.content;
            self.isConfirm = self.resolve.isConfirm;
            self.confirmLabel = self.resolve.confirmLabel;
            self.goBackLabel = self.resolve.goBackLabel;
            self.coupon = self.resolve.coupon;
            self.succeedContent = self.resolve.succeedContent;
            self.errorContent = self.resolve.errorContent;
            self.notFoundErrorContent = self.resolve.notFoundErrorContent;
            self.expiredErrorContent = self.resolve.expiredErrorContent;
            self.PinCode1Invalid = self.resolve.PinCode1Invalid;
            self.PinCode2Invalid = self.resolve.PinCode2Invalid;
            self.BothPinCodeInvalid = self.resolve.BothPinCodeInvalid;
            self.PinCode1AlreadyUsed = self.resolve.PinCode1AlreadyUsed;
            self.PinCode2AlreadyUsed = self.resolve.PinCode2AlreadyUsed;
            self.BothPinCodeAlreadyUsed = self.resolve.BothPinCodeAlreadyUsed;
            self.InvalidCombination = self.resolve.InvalidCombination;

            initPreventLoadingModalClosingBehaviour();
        };

        function submitConfirmed() {
            self.succeeded = true;
            self.showLoader = true;
            var submitPromise = promoService.submitGeneralMillsCoupon(self.coupon);
            submitPromise.then(function (result) {
                self.showLoader = false;
                if (result != null) {
                    if (result.Status == 1) {
                        self.modalContent = self.succeedContent;
                    }
                    else if (result.Status == 2) {
                        self.title = "";
                        self.modalContent = self.errorContent;
                        self.failed = true;
                    }
                    else if (result.Status == 3) {
                        self.title = "";
                        self.modalContent = self.expiredErrorContent;
                        self.failed = true;
                    }
                    else if (result.Status == 4) {
                        self.title = ""; 
                        self.modalContent = self.PinCode1Invalid.replace(/#|_/g, self.coupon.PinCode1);//self.notFoundErrorContent;
                        self.failed = true;
                    }
                    else if (result.Status == 5) {
                        self.title = "";
                        self.modalContent = self.PinCode2Invalid.replace(/#|_/g, self.coupon.PinCode2);
                        self.failed = true;
                    }
                    else if (result.Status == 6) {
                        self.title = "";
                        self.modalContent = self.BothPinCodeInvalid.replace(/#|_/g, self.coupon.PinCode1, self.coupon.PinCode2);
                        self.failed = true;
                    }
                    else if (result.Status == 7) {
                        self.title = "";
                        self.modalContent = self.PinCode1AlreadyUsed.replace(/#|_/g, self.coupon.PinCode1);
                        self.failed = true;
                    }
                    else if (result.Status == 8) {
                        self.title = "";
                        self.modalContent = self.PinCode2AlreadyUsed.replace(/#|_/g, self.coupon.PinCode2);
                        self.failed = true;
                    }
                    else if (result.Status == 9) {
                        self.title = "";
                        self.modalContent = self.BothPinCodeAlreadyUsed.replace(/#|_/g, self.coupon.PinCode1, self.coupon.PinCode2);
                        self.failed = true;
                    }
                    else if (result.Status == 10) {
                        self.title = "";
                        self.modalContent = self.InvalidCombination.replace(/#|_/g, self.coupon.PinCode1, self.coupon.PinCode2);
                        self.failed = true;
                    }

                }
            });
            $timeout(function () { $scope.$apply(); });
        }

        function initPreventLoadingModalClosingBehaviour() {
            var preventModalClose = $scope.$on('modal.closing', function (event, reason, closed) {
                if (self.showLoader) {
                    event.preventDefault();
                }
            });

            $scope.$on("$destroy", function () {
                preventModalClose();
            });
        }
    }
})();