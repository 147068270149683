angular.module("loadMoreGridFactory", [])
    .factory("loadMoreGrid", function () {
        return function () {
            var main = {};

            main.init = function (itemsArray, itemsPerRequest, totalItems, itemsPerRowSettings) {
                updateSettings(itemsArray, itemsPerRequest, totalItems, itemsPerRowSettings);
            };

            main.addItems = function (items) {
                main.allItems = main.allItems.concat(items);
                update();
            };

            function updateSettings(itemsArray, itemsPerRequest, totalItems, itemsPerRowSettings) {
                main.itemsPerRowSettings = itemsPerRowSettings;
                main.itemsPerRequest = itemsPerRequest;
                main.totalItems = totalItems;
                main.resultsPerRow = getItemsPerRow(window.innerWidth);
                main.showMoreOptionVisible = false;
                main.allItems = itemsArray;
                main.resultRows = [];
                update();
            }

            function update() {
                updateResultRows();
                updateShowMoreOptionVisible();
            }

            function updateResultRows() {
                var result = [];

                //Check if we can update existing rows
                if (main.resultRows.length > 1 && main.resultRows[0].length === main.resultsPerRow
                    || main.resultRows.length === 1 && main.resultRows[0].length === main.resultsPerRow - 1) { //first row is the only row
                    var itemsRendered = main.resultRows.length * main.resultsPerRow;
                    var itemsToRender = main.allItems.length - itemsRendered;
                    var iterations = Math.ceil(itemsToRender / main.resultsPerRow);

                    //get new rows
                    for (var i = main.resultRows.length; i < main.resultRows.length + iterations; i++) {
                        var tempRow = main.allItems.slice(i * main.resultsPerRow, i * main.resultsPerRow + main.resultsPerRow);
                        result.push(tempRow);
                    }

                    //add item that was replaced by the "See More" button
                    main.resultRows[main.resultRows.length - 1].push(main.allItems[itemsRendered - 1]);

                    //add new rows
                    main.resultRows = main.resultRows.concat(result);
                } else {
                    //Row size has changed or rows are unitialized
                    var iterations = Math.ceil(main.allItems.length / main.resultsPerRow);

                    for (var i = 0; i < iterations; i++) {
                        result.push(main.allItems.slice(i * main.resultsPerRow, i * main.resultsPerRow + main.resultsPerRow));
                    }
                    main.resultRows = result;
                }
            }

            function updateShowMoreOptionVisible() {
                main.showMoreOptionVisible = main.allItems.length < main.totalItems;
            }

            function getItemsPerRow(width) {
                for (var i = main.itemsPerRowSettings.length - 1; i >= 0; i--) {
                    if (main.itemsPerRowSettings[i][0] <= width) {
                        if (main.itemsPerRowSettings[i][1] > 0) {
                            return main.itemsPerRowSettings[i][1];
                        }
                        else {
                            return 1;
                        }
                    }
                }
                return 1;
            }

            return main;
        };

    });