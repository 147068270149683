angular.module("moviesServiceModule", [])

    .service("moviesService", ["$http", "$q",  function ($http, $q) {

        var self = this;

        self.getAllMovies = function (requestObject) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.MovieApiUrl : js.settings.OldMovieApiUrl)
                + "/getmoviesforoneposter";

            requestObject.movieType = 1;
            requestObject.showTimeType = 0;
            requestObject.showtimeStatus = 0;
            requestObject.marketLanguageCodeFilter = requestObject.language === "en-us";

            var deferredRequest = $q.defer();
			$http({
				url: requestUri,
				params: requestObject,
				method: 'GET',
				headers: {
						'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
				}
			}).then(success, error);
            
            return deferredRequest.promise;

            function success(resp) {
                try {
                    var result = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(result);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function error(resp) {
                deferredRequest.reject(resp);
            }

        };

        self.getAllMoviesWithShowtimes = function (requestObject) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.MovieApiUrl : js.settings.OldMovieApiUrl)
                + "/getmoviesforoneposter";

            requestObject.movieType = 0;
            requestObject.showTimeType = 0;
            requestObject.showtimeStatus = 1;
            requestObject.marketLanguageCodeFilter = requestObject.language === "en-us";

            var deferredRequest = $q.defer();

			$http({
				url: requestUri,
				params: requestObject,
				method: 'GET',
				headers: {
						'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
				}
			}).then(success, error);

            return deferredRequest.promise;

            function success(resp) {
                try {
                    var result = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(result);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function error(resp) {
                deferredRequest.reject(resp);
            }

        };

        self.getComingSoonMovies = function (requestObject) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.MovieApiUrl : js.settings.OldMovieApiUrl)
                + "/getmoviesforoneposter";

            requestObject.movieType = 1;
            requestObject.showTimeType = 2;
            requestObject.marketLanguageCodeFilter = requestObject.language === "en-us";

            var deferredRequest = $q.defer();
			
			$http({
				url: requestUri,
				params: requestObject,
				method: 'GET',
				headers: {
						'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
				}
			}).then(success, error);
			
            return deferredRequest.promise;

            function success(resp) {
                try {
                    var result = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(result);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function error(resp) {
                deferredRequest.reject(resp);
            }
        };


        self.getNowPlayingMovies = function (requestObject) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.MovieApiUrl : js.settings.OldMovieApiUrl)
                + "/getmoviesforoneposter";

            requestObject.movieType = 1;
            requestObject.showTimeType = 1;
            requestObject.marketLanguageCodeFilter = requestObject.language === "en-us";

            var deferredRequest = $q.defer();

			$http({
				url: requestUri,
				params: requestObject,
				method: 'GET',
				headers: {
						'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
				}
			}).then(success, error);

            return deferredRequest.promise;

            function success(resp) {
                try {
                    var result = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(result);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function error(resp) {
                deferredRequest.reject(resp);
            }

        };

        self.getEvents = function (requestObject) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.MovieApiUrl : js.settings.OldMovieApiUrl)
                + "/getmoviesforoneposter";

            requestObject.movieType = 2;
            requestObject.showTimeType = 0;
            requestObject.marketLanguageCodeFilter = requestObject.language === "en-us";

            var deferredRequest = $q.defer();

			$http({
				url: requestUri,
				params: requestObject,
				method: 'GET',
				headers: {
						'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
				}
			}).then(success, error);

            return deferredRequest.promise;

            function success(resp) {
                try {
                    var result = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(result);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function error(resp) {
                deferredRequest.reject(resp);
            }
        };

        self.getTheatreAllMovies = function (requestObject, theatreId) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl)
                + "/" + theatreId + "/moviesoneposter";
            var params = {
                movieType: 1,
                language: requestObject.language,
                marketLanguageCodeFilter: false
            };

            if (requestObject.movieFormat) {
                params.movieFormat = requestObject.movieFormat;
            }

            var deferredRequest = $q.defer();

            $http({ 
				url: requestUri, 
				params: params, 
				method: "GET",
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                    }
                }).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                var mappedData;
                try {
                    mappedData = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(mappedData);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }
        };

        self.getTheatreNowPlayingMovies = function (requestObject, theatreId) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl)
                + "/" + theatreId + "/moviesoneposter";

            var params = { language: requestObject.language, showTimeType: 1, movieType: 1, marketLanguageCodeFilter: false };

            if (requestObject.movieFormat) {
                params.movieFormat = requestObject.movieFormat;
            }

            var deferredRequest = $q.defer();

            $http({ 
				url: requestUri, 
				params: params, 
				method: "GET",
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                    }
                }).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                var mappedData;
                try {
                    mappedData = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(mappedData);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }
        };


        self.getTheatreComingSoonMovies = function (requestObject, theatreId) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl)
                + "/" + theatreId + "/moviesoneposter";

            var params = {
                language: requestObject.language,
                showTimeType: 2,
                movieType: 1,
                marketLanguageCodeFilter: false
            };

            if (requestObject.movieFormat) {
                params.movieFormat = requestObject.movieFormat;
            }

            var deferredRequest = $q.defer();

            $http({ 
				url: requestUri, 
				params: params, 
				method: "GET",
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                    }
                }).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                var mappedData;
                try {
                    mappedData = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(mappedData);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }
        };

        self.getTheatreEventMovies = function (requestObject, theatreId) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl)
                + "/" + theatreId + "/moviesoneposter";

            var params = {
                language: requestObject.language,
                movieType: 2,
                marketLanguageCodeFilter: false
            };

            var deferredRequest = $q.defer();

            $http({ 
				url: requestUri, 
				params: params, 
				method: "GET",
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                    }
                }).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                var mappedData;
                try {
                    mappedData = mapMovieArrayResponse(resp.data);
                    deferredRequest.resolve(mappedData);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }
        };

        self.getTheatreMoviesWithDates = function (theatreId, language) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl)
                + "/" + theatreId + "/availablemoviesoneposter/dates";

            var params = {
                language: language,
                skip: 0,
                take: 10000,
                marketLanguageCodeFilter: false
            };

            var deferredRequest = $q.defer();

            $http({ 
				url: requestUri, 
				params: params, 
				method: "GET",
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                    }
                }).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                var mappedData;
                try {
                    mappedData = mapMovieWithDates(resp.data);
                    deferredRequest.resolve(mappedData);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }
        };

        self.getTheatreMoviesWithShowtimesByDate = function (locationId, dateString, languageCode) {
            var requestUri = (js.settings.EnableSecureApiUrl == "true" ? js.settings.BaseUrl + js.settings.TheatreApiUrl : js.settings.OldTheatreApiUrl)
                + "/" + locationId + "/availablemovies/showtimesoneposter";

            var params = {
                showDate: dateString,
                language: languageCode,
                marketLanguageCodeFilter: false
            };
            var deferredRequest = $q.defer();
            $http({ 
				url: requestUri, 
				params: params, 
				method: "GET",
                headers: {
                        'Ocp-Apim-Subscription-Key': js.settings.ApiTargetEnvironmentValue
                    }
                }).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                var mappedData;
                try {
                    mappedData = mapMoviesWithShowtimes(resp.data, languageCode);
                    deferredRequest.resolve(mappedData);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }

        };

        self.getBrightCoveVideoDetails = function (brightCoveId, cineplexVideoId, languageCode) {
            var requestUri = '/api/v1/movies/getbrightcovevideodetails';
            var deferredRequest = $q.defer();
            var params = {
                BrightcoveVideoId: brightCoveId,
                LanguageCode: languageCode
                };
            $http({
                url: requestUri,
                params:params,
                method: "GET"
            }).then(requestSuccess, requestFailure);

            return deferredRequest.promise;

            function requestSuccess(resp) {
                try {
                    var category = resp.data.toString();
                    var detailJson = {
                        "category": category,
                        "brightCoveId": brightCoveId,
                        "cineplexVideoId": cineplexVideoId
                    }
                    deferredRequest.resolve(detailJson);
                }
                catch (error) {
                    deferredRequest.reject(error);
                }
            }

            function requestFailure(resp) {
                deferredRequest.reject(resp);
            }

        };


        function mapMovieArrayResponse(resp) {
            var moviesArray = [];
            resp.data.forEach(function (movieItem) {
                moviesArray.push(new movieObject(movieItem));
            });
            return new movieArrayObject(moviesArray, resp.totalCount);
        }

        function movieArrayObject(movies, totalCount) {
            this.data = movies;
            this.totalCount = totalCount;
        }

        function movieObject(movieConfigObject) {
            this.filmId = movieConfigObject.id;
            this.filmName = movieConfigObject.name;
            this.showDate = movieConfigObject.firstShowStartDate;
            this.marketLanguageCode = movieConfigObject.marketLanguageCode;
            this.smallPosterImageUrl = movieConfigObject.smallPosterImageUrl;
            this.mediumPosterImageUrl = movieConfigObject.mediumPosterImageUrl;
            this.largePosterImageUrl = movieConfigObject.largePosterImageUrl;
            this.trailerUrl = movieConfigObject.trailerUrl;
            this.brightcoveVideoId = movieConfigObject.brightcoveVideoId;
            this.filmUrl = movieConfigObject.urlSlug;
            this.hasShowtimes = movieConfigObject.hasShowtimes;
            this.isNowPlaying = movieConfigObject.isNowPlaying;
            this.isComingSoon = movieConfigObject.isComingSoon;
            this.isEvent = movieConfigObject.isEvent;
            this.formats = movieConfigObject.formats;
            this.seriesTicketingUrl = movieConfigObject.seriesTicketingUrl;
            this.seriesTicketingVIPUrl = movieConfigObject.seriesTicketingVIPUrl
        }

        function mapMovieWithDates(resp) {
            var moviesArray = [];
            resp.data.forEach(function (movie) {
                moviesArray.push(new movieWithDatesObject(movie));
            });
            return moviesArray;
        }

        function movieWithDatesObject(movieConfigObject) {
            this.filmId = movieConfigObject.movie.id;
            this.filmName = movieConfigObject.movie.name;
            this.movieUtcDates = movieConfigObject.showDates;
            this.seriesTicketingUrl = movieConfigObject.movie.seriesTicketingUrl;
            this.seriesTicketingVIPUrl = movieConfigObject.movie.seriesTicketingVIPUrl
        }

        function mapMoviesWithShowtimes(resp, languageCode) {
            var resultArray = [];
            resp.data.forEach(function (movieWithShowtimes) {
                resultArray.push(new movieWithShowtimesObject(movieWithShowtimes, languageCode));
            });
            return resultArray;
        }

        function movieWithShowtimesObject(movieConfigObject, languageCode) {
            this.duration = movieConfigObject.movie.duration;
            this.filmId = movieConfigObject.movie.id;
            this.filmName = movieConfigObject.movie.name;
            this.theatricalReleaseDate = movieConfigObject.movie.firstShowStartDate;
            this.smallPosterImageUrl = movieConfigObject.movie.smallPosterImageUrl;
            this.mediumPosterImageUrl = movieConfigObject.movie.mediumPosterImageUrl;
            this.largePosterImageUrl = movieConfigObject.movie.largePosterImageUrl;
            this.brightcoveVideoId = movieConfigObject.movie.brightcoveVideoId;
            this.mpaaRating = movieConfigObject.movie.mpaaRating;
            this.urlSlug = movieConfigObject.movie.urlSlug;
            this.seriesTicketingUrl = movieConfigObject.movie.seriesTicketingUrl;
            this.seriesTicketingVIPUrl = movieConfigObject.movie.seriesTicketingVIPUrl
            this.formats = movieConfigObject.movie.formats;
            this.passRestriction = getPassRestrictions(movieConfigObject.showtimeDetails);
            this.showTimeDetails = mapMovieExperiences(movieConfigObject.showtimeDetails);
        }

        function getPassRestrictions(movieShowtimesByExperience) {
            for (var i = 0; i < movieShowtimesByExperience.length; i++) {
                if (!movieShowtimesByExperience[i].isPassesAllowed) {
                    return new noPassObject(movieShowtimesByExperience[i]);
                }
            }
            return;
        }

        function noPassObject(configObject) {
            this.hasPassRestriction = !configObject.isPassesAllowed;
            this.noPassText = configObject.noPassText;
            this.noPassDescription = configObject.noPassDescription;
        }

        function mapMovieExperiences(movieShowtimesByExperience) {
            var movieShowtimesByExperienceArray = [];
            movieShowtimesByExperience.forEach(function (movieShowtimes) {
                movieShowtimesByExperienceArray.push(new showtimesExperienceObject(movieShowtimes));
            });
            return movieShowtimesByExperienceArray;

        }


        function showtimesExperienceObject(configObject) {
            this.experienceType = configObject.experienceType;
            this.presentationType = configObject.presentationType;
            this.attributeID = configObject.attributeID;
            this.movieExperienceImageClass = configObject.movieExperienceImageName;
            this.experienceBannerAltText = configObject.experienceBannerAltText;
            this.movieExperienceTitle = configObject.experienceType;
            this.presentationTypeTitle = configObject.presentationTypeTitle;
            this.areaCode = configObject.areaCode;
            this.showtimes = mapShowtimes(configObject.showtimes);
        }

        function mapShowtimes(showtimesArray) {
            var resultArray = [];
            showtimesArray.forEach(function (showtime) {
                resultArray.push(new showtimesObject(showtime));
            });
            return resultArray;
        }

        function showtimesObject(configObject) {
            this.showStartDateTimeUtc= configObject.showStartDateTimeUtc;
            this.isReservedSeating= configObject.isReservedSeating;
            this.isShowtimeEnabled = configObject.isShowtimeEnabled;
            this.isOnlineTicketingAvailable = configObject.isOnlineTicketingAvailable;
            this.ticketingUrl = configObject.ticketingUrl;
            this.vistaSessionId= configObject.vistaSessionId;
            this.isSoldOut= configObject.isSoldOut;
            this.inThePast = configObject.inThePast;
            this.seatMapUrl = configObject.seatMapUrl;
        }

        //Deprecated since running times formatting has already done in csharp
        function formatDuration(durationInMins, languageCode) {
            var resultMins = parseInt(durationInMins);
            if(languageCode === "fr-ca"){
                return Math.floor(resultMins / 60) + " h " + resultMins % 60;
            }
            else {
                return Math.floor(resultMins / 60) + "h " + resultMins % 60 + "m";
            }
        }

    }]);