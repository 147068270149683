angular.module("generalMillsCouponComponent", ["safeHtmlFilter", "ui.bootstrap"])
    .component("generalMillsCoupon",
    {
        templateUrl: "/Scripts/app/promotions/general-mills/general-mills-coupon.template.v2.html",
        bindings: {
            languageResource: "<",
            isTicketsPromotionExpired: "@",
            isConcessionsPromotionExpired: "@",
            pinCodeRegex: "@",
            emailAddressRegex: "@"
        },
        controller: ["$scope", "$timeout", "$uibModal", generalMillsCouponController]
    });

function generalMillsCouponController($scope, $timeout, $uibModal) {
    var self = this;

    self.isTicketsExpired = false;
    self.isConcessionsExpired = false;
    self.type = {
        none: -1,
        tickets: 0,
        concessions: 1
    };
    self.modalType = {
        tickets: 0,
        concessions: 1,
        confirm: 2,
        termsAndConditions: 3
    };
    self.coupon = {
        GeneralMillsCouponType: 0,
        PinCode1: '',
        PinCode2: '',
        EmailAddress: '',
        ConfirmEmailAddress: ''
    };


    self.$onInit = initFunction;
    self.couponSelected = couponSelected;
    self.openModal = openModal;
    self.submitForm = submitForm;
    self.emailsMatch = emailsMatch;
    self.isFormInvalid = function (isInvalid) {
        if (isInvalid || !self.emailsMatch() || !pinsAreValid())
            return true;
        else return false;
    }
    self.startsWithValidIdentifier = startsWithValidIdentifier;
    self.pinsAreValidCombination = pinsAreValidCombination;
    self.pinsAreMatching = pinsAreMatching;


    function initFunction() {
        self.isTicketsExpired = self.isTicketsPromotionExpired === "true";
        self.isConcessionsExpired = self.isConcessionsPromotionExpired === "true";
        self.emailAddressRegex =
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    }

    function couponSelected(type, isViable) {
        if (type != null && isViable) {
            if (type == self.type.tickets) {
                self.coupon.GeneralMillsCouponType = self.type.tickets;
            }
            else if (type == self.type.concessions) {
                self.coupon.GeneralMillsCouponType = self.type.concessions;
            }
            $timeout(function () {
                $("body, html").animate({ scrollTop: ($("#gm-step-two-form").offset().top) }, 500);
            });
        }
    }

    function submitForm(type) {
        var emailsAreMatching = emailsMatch();
        var validPinsEntered = pinsAreValid();
        if ($scope.submissionForm.$valid && emailsAreMatching && validPinsEntered) {
            self.coupon.PinCode1 = self.coupon.PinCode1.toUpperCase();
            self.coupon.PinCode2 = self.coupon.PinCode2.toUpperCase();
            openModal(type);
        }
        //else if ($scope.submissionForm.$invalid || !emailsAreMatching) {
        //    $scope.submissionForm.$setDirty();
        //}
    }

    function getModalContent() {
        if (self.coupon.GeneralMillsCouponType == self.type.tickets) {
            var pin1 = self.coupon.PinCode1.toUpperCase();
            var pin2 = self.coupon.PinCode2.toUpperCase();

            //Two codes, Free admission offer
            if (pin2) {
                if (pin1.startsWith(self.languageResource.adultTicketCouponIdentifier)
                    && pin2.startsWith(self.languageResource.adultTicketCouponIdentifier)) {
                    return self.languageResource.confirmTicketsContent;
                } else if (pin1.startsWith(self.languageResource.childTicketCouponIdentifier)
                    && pin2.startsWith(self.languageResource.childTicketCouponIdentifier)) {
                    return self.languageResource.confirmChildTicketContent;
                }
            //One code, Buy One Get One offer
            } else {
                if (pin1.startsWith(self.languageResource.adultTicketCouponIdentifier)) {
                    return self.languageResource.confirmBuyOneGetOneContent;
                } else if (pin1.startsWith(self.languageResource.childTicketCouponIdentifier)) {
                    return self.languageResource.confirmChildBuyOneGetOneContent;
                }
            }
        }
        else if (self.coupon.GeneralMillsCouponType == self.type.concessions)
            self.content = self.languageResource.confirmConcessionsContent;
    }

    function openModal(type) {
        self.title = "", self.content = "", self.isConfirm = false;
        if (type != null) {
            if (type == self.modalType.tickets && !self.isTicketsExpired) {
                self.title = self.languageResource.ticketsLearnMoreTitle;
                self.content = self.languageResource.ticketsLearnMoreContent;
            } else if (type == self.modalType.concessions && !self.isConcessionsExpired) {
                self.title = self.languageResource.concessionsLearnMoreTitle;
                self.content = self.languageResource.concessionsLearnMoreContent;
            } else if (type == self.modalType.confirm && self.coupon.GeneralMillsCouponType != self.type.none) {
                self.isConfirm = true;
                self.title = self.languageResource.confirmTitle;
                self.content = getModalContent();
            } else if (type == self.modalType.termsAndConditions) {
                self.title = self.languageResource.termsAndConditionsLabel;
                self.content = self.languageResource.termsAndConditionsContent;
            }

            if (type != self.modalType.confirm ||
                (type == self.modalType.confirm && self.coupon.GeneralMillsCouponType != self.type.none)) {
                var modalInstance = $uibModal.open({
                    template:
                        '<a href class="fa fa-close modal-close"></a><general-mills-promo-coupon-modal-component></general-mills-promo-coupon-modal-component>',
                    component: "generalMillsPromoCouponModal",
                    windowClass: "general-mills-coupon-modal",
                    resolve: {
                        title: function () {
                            return self.title;
                        },
                        content: function () {
                            return self.content;
                        },
                        isConfirm: function () {
                            return self.isConfirm;
                        },
                        confirmLabel: function () {
                            return self.languageResource.confirmLabel;
                        },
                        goBackLabel: function () {
                            return self.languageResource.modalBackButtonLabel;
                        },
                        coupon: function () {
                            return self.coupon;
                        },
                        succeedContent: function () {
                            return self.languageResource.succeedContent;
                        },
                        errorContent: function () {
                            return self.languageResource.sendingCouponErrorContent;
                        },
                        expiredErrorContent: function () {
                            return self.languageResource.offerExpiredErrorContent;
                        },
                        PinCode1Invalid: function () {
                            return self.languageResource.pinNotFoundErrorContent;
                        },
                        PinCode2Invalid: function () {
                            return self.languageResource.pinNotFoundErrorContent;
                        },
                        BothPinCodeInvalid: function () {
                            return self.languageResource.invalidPinCode1PinCode2;
                        },
                        PinCode1AlreadyUsed: function () {
                            return self.languageResource.pincodeAlreadyUsed;
                        },
                        PinCode2AlreadyUsed: function () {
                            return self.languageResource.pincodeAlreadyUsed;
                        },
                        BothPinCodeAlreadyUsed: function () {
                            return self.languageResource.pincode1PinCode2AlreadyUsed;
                        },
                        InvalidCombination: function () {
                            return self.languageResource.invalidPinCombination;
                        }
                    }
                });

                //required for boostrap modal to function properly
                modalInstance.result.then(
                    function () { },
                    function () { });
            }
        }
    }

    function emailsMatch() {
        return self.coupon == null ||
            (self.coupon.EmailAddress == null ||
                self.coupon.EmailAddress == "") ||
            (self.coupon.ConfirmEmailAddress == null ||
                self.coupon.ConfirmEmailAddress == "") ||
            angular.lowercase(self.coupon.EmailAddress) == angular.lowercase(self.coupon.ConfirmEmailAddress);
    }

    function startsWithValidIdentifier(pin) {
        pin = pin.toUpperCase();
        return pin.startsWith(self.languageResource.adultTicketCouponIdentifier)
            || pin.startsWith(self.languageResource.childTicketCouponIdentifier);
    }

    function pinsAreValidCombination() {
        var pin1 = self.coupon.PinCode1.toUpperCase();
        var pin2 = self.coupon.PinCode2.toUpperCase();

        if (!pin2)
            return true;
        else
            return pin1
                && startsWithValidIdentifier(pin1)
                && startsWithValidIdentifier(pin2)
                && pin1.startsWith(pin2.substring(0, 2));
    }

    function pinsAreMatching() {
        return self.coupon.PinCode1.toUpperCase() === self.coupon.PinCode2.toUpperCase();
    }

    function pinsAreValid() {
        if (self.coupon.PinCode2)
            return !pinsAreMatching() && pinsAreValidCombination();
        else
            return self.coupon.PinCode1
                && startsWithValidIdentifier(self.coupon.PinCode1);
    }
}