(function () {
    angular.module("movieCashComponent", ["theatresServiceModule", "showtimesServiceModule", "promoServiceModule", "showtimesFunctionsService", "safeHtmlFilter", "ui.bootstrap"])
    .component("movieCash",
    {
        templateUrl: "/Scripts/app/movie-cash/movie-cash.template.v4.html",
        bindings: {
            promoLabel: "@",
            movieLabel: "@",
            theatreLabel: "@",
            dateLabel: "@",
            noPromoDatesMsg: "@",
            promoImgUrl: "@",
            promoImgAlt: "@",
            seatMapLink: "@",
            soldOutText: "@",
            todayText: "@",
            tomorrowText: "@",
            reclinerText: "@",
            validateButtonText: "@",
            promoValidateSuccessText: "@",
            promoValidateFailText: "@",
            languageCode: "@",
            promoPlaceholder: "@",
            selectedPlaceholder: "@",
            theatrePlaceholder: "@",
            moviePlaceholder: "@",
            datePlaceholder: "@",
            theatreNotFoundMessage: "@",
            movieNotFoundMessage: "@",
            fallbackErrorHtml: "@",
            agilityCommonShowtimesViewSeatMap: "@",
            agilityCommonBuyTickets: "@",
            movieList: "<",
            initialPromoCode: "@",
        },
        controller: ["theatresService", "showtimesService", "promoService", "showtimesFunctions", "$scope", "$q", "$timeout", "$sce", movieCashController]
    }
    );

    function movieCashController(theatresService, showtimesService, promoService, showtimesFunctions, $scope, $q, $timeout, $sce) {

        var main = this;

        main.connectInitializationAttempts = 0;
        main.promoCodeInput = '';
        main.promoCode = '';
        main.promoCodeValid = false;
        main.promoCodeInvalid = false;
        main.widgetLoaded = false;
        main.showFallbackError = false;
        main.showShowtimeSelectionPrompt = false;
        main.showtimesPaneDisplayed = false;
        main.isSeriesTicketing = false;
        main.isVipSeriesTicketing = false;
        main.regularSeriesTicketingRedirectionLink = '';
        main.vipSeriesTicketingRedirectionLink = '';
        main.midnightShowtimes = false;
        main.allTheatres = [];
        main.theatreOptions = [];
        main.dateOptions = [];
        main.movieOptions = [];
        main.theatresEligable = [];
        main.datesEligable = [];
        main.selectTheatreDisabled = true;
        main.selectDateDisabled = true;
        main.selectedMovieDisabled = false;
        main.showInvalidTheatreMessage = false;
        main.isAllDayAllowed = true;
        main.isAllLocationsAllowed = true;
        main.selectedTheatre = '';
        main.selectedMovie = '';
        main.selectedDate = '';
        main.noTheatreResults = false;
        main.noMovieResults = false;
        main.isValidating = false;

        main.showtimes = [];

        main.$onInit = onInitFunction;

        main.generateSpriteClasses = showtimesFunctions.generateSpriteClasses;

        main.generateShowtimeMetaContent = showtimesFunctions.generateShowtimeMetaContent;

        main.createIdForShowtime = showtimesFunctions.createIdForShowtime;

        main.createShowtimeText = showtimesFunctions.createShowtimeText;

        main.createLinkForShowtime = createPromoLinkForShowtime;

        main.showtimeVisible = showtimeVisible;

        main.trackClick = trackClick;

        //TODO: GENERALIZE ONCE ABLE
        function showtimeVisible(isSoldOut, inThePast) {
            return !isSoldOut && !inThePast;
        }

        main.showtimeSeatSelectionVisible = showtimesFunctions.showtimeSeatSelectionVisible;

        main.submitPromoCode = submitPromoCode;

        main.openShowtimeSeatMap = showtimesFunctions.openShowtimeSeatMap;

        main.noTicketsAvailable = showtimesFunctions.noTicketsAvailable;

        $scope.$watch(function () { return main.promoCodeValid; }, enableDisableTheatreSelect);

        $scope.$watch(function () { return main.selectedMovie; }, enableDisableTheatreSelect);

        $scope.$watch(function () { return main.selectedTheatre; }, enableDisableDateSelect);

        $scope.$watchGroup([
    function () { return main.selectedDate; },
    function () { return main.selectedMovie; },
    function () { return main.selectedTheatre; }],
    displayShowtimes);

        return main;

        function submitPromoCode() {
            if (main.promoCodeInput !== "") {
                main.promoCode = main.promoCodeInput;
                main.promoCodeInvalid = false;
                main.promoCodeValid = false;
                main.isValidating = true;
                promoService.validatePromoCode(main.promoCode).then(promoSuccess, showError);
            }
        }

        function promoSuccess(resp) {
            if (resp.status === 1) {
                main.promoCode = main.promoCodeInput;
                main.promoCodeValid = true;
                main.widgetLoaded = true;
                main.theatresEligable = resp.theatresAllowed;
                main.datesEligable = resp.daysAllowed;
                main.isAllDayAllowed = resp.isAllDayAllowed;
                main.isAllLocationsAllowed = resp.isAllLocationsAllowed;

            }
            else {
                main.promoCodeInvalid = true;
                main.selectTheatreDisabled = true;
                main.selectDateDisabled = true;
            }
            main.isValidating = false;
            scopeApply();
        }

        function filterTheatres(allTheatres, locationIds) {
            if (!main.isAllLocationsAllowed) {
                return allTheatres.filter(function (theatre) {
                    return locationIds.indexOf(parseInt(theatre.id)) > -1;
                });
            }
            return allTheatres;
        }

        function getSelectedTheatre() {
            return main.allTheatres.filter(function (theatre) { return theatre.id === parseInt(main.searchByTheatre.selectedTheatre); })[0];
        }

        function enableDisableTheatreSelect(newVal, oldVal) {
            if (main.selectedMovie !== "" && newVal != null && main.promoCodeValid) {
                getTheatresForMovie(parseInt(main.selectedMovie));
            }
            else {
                main.selectedTheatre = '';
                main.selectTheatreDisabled = true;
                main.selectDateDisabled = true;
                scopeApply();
            }
        }

        function getTheatresForMovie(movieId) {
            theatresService.getMovieTheatres(movieId, main.languageCode).then(getTheatresForMovieSuccess, showError);
        }

        function getTheatresForMovieSuccess(response) {
            var theatreList = mapTheatreObjects(response);
            main.theatreOptions = filterTheatres(theatreList, main.theatresEligable);
            main.selectTheatreDisabled = false;
            scopeApply();
        }

        function enableDisableDateSelect(newVal, oldVal) {
            if (newVal !== "" && newVal != null && main.promoCodeValid) {
                main.dateOptions = formatAndFilterDateResults(getSelectedItem(main.theatreOptions, newVal).movieUtcDates);
                if (main.dateOptions.length > 0) {
                    main.showInvalidTheatreMessage = false;
                    main.selectDateDisabled = false;
                }
                else {
                    main.selectDateDisabled = true;
                    main.showInvalidTheatreMessage = true;
                }
                scopeApply();
            }
            else {
                main.selectedDate = '';
                main.selectDateDisabled = true;
                main.showInvalidTheatreMessage = false;
                scopeApply();
            }
        }

        function displayShowtimes(newVal, oldVal) {
            if (newVal[0] !== '' && newVal[1] !== '' && newVal[2] !== '' && !(newVal[0] === null || newVal[1] === null || newVal[2] === null)) {
                main.showtimesPaneDisplayed = true;
                main.showShowTimeSelectionError = false;
                getShowtimes(newVal[0], newVal[1], newVal[2], main.languageCode);
            }
            else {
                main.showtimesPaneDisplayed = false;
            }
        }

        function getShowtimes(dateId, filmId, locationId, languageCode) {
            var dateOption = main.dateOptions[parseInt(dateId)].dateText;
            var dateRequestString = moment.utc(dateOption, "ddd, MMM DD YYYY").format("MM/DD/YYYY");
            showtimesService.getShowtimes(parseInt(locationId), parseInt(filmId), dateRequestString, languageCode).then(showtimesRequestSuccess, showError);
            scopeApply();
        }

        function showtimesRequestSuccess(response) {
            main.showtimes = addMidnightShowtimeProperties(response);
            scopeApply();
        }

        //INITIALIZATION FUNCTIONS

        function onInitFunction() {
            initSelectizeComponents();
            initMovieCash();
            initSeatMapData();
        }

        function initSelectizeComponents() {

            main.theatreSelectizeOptions = {
                //optgroups: [
                //    { id: 'regular', name: main.allTheatresDropdownHeading }
                //],
                placeholder: main.theatrePlaceholder,
                labelField: 'text',
                valueField: 'id',
                searchField: ['searchTerm'],
                //optgroupField: 'type',
                //optgroupLabelField: 'name',
                //optgroupValueField: 'id',
                //optGroupOrder: ['fav', 'searched', 'nearBy', 'regular'],
                //lockOptgroupOrder: true,
                onDropdownOpen: selectizeAdjustOnOpen,
                onType: searchByTheatreNoTheatreResults,
                onBlur: clearErrors,
                onDropdownClose: selectizeAdjustOnClose
            };
            main.movieSelectizeOptions = {
                //optgroups: [{ id: 'mov', name: main.selectMovieDropdownHeadingSearchByTheatre }],
                placeholder: main.moviePlaceholder,
                labelField: 'text',
                valueField: 'id',
                searchField: ['text'],
                //optgroupField: 'type',
                //optgroupLabelField: 'name',
                //optgroupValueField: 'id',
                onDropdownOpen: selectizeAdjustOnOpen,
                onType: searchByTheatreNoMovieResults,
                onBlur: clearErrors,
                onDropdownClose: selectizeAdjustOnClose
            };
            main.dateSelectizeOptions = {
                //optgroups: [{ id: 'date', name: main.selectDateDropdownHeading }],
                placeholder: main.datePlaceholder,
                labelField: 'text',
                valueField: 'id',
                searchField: ['text'],
                //optgroupField: 'type',
                //optgroupLabelField: 'name',
                //optgroupValueField: 'id',
                onInitialize: initDateInput,
                onDropdownOpen: selectizeAdjustOnOpenDate,
                onDropdownClose: selectizeAdjustOnCloseDate
            };
        }

        function initSeatMapData() {
            if (typeof (window.CINEPLEX) !== "undefined") {
                window.CINEPLEX.seatMapTicketingLink = main.seatMapLink;
            }
            else {
                showError("window.CINEPLEX IS NOT DEFINED!");
            }
        }

        function initMovieCash() {
            main.movieOptions = mapMovieObjects(main.movieList);
            main.selectedMovie = main.movieOptions[0].id;
            if (main.initialPromoCode) {
                main.promoCodeInput = main.initialPromoCode;
                main.promoCode = main.initialPromoCode;
                promoService.validatePromoCode(main.initialPromoCode).then(promoSuccess, showError);
            }
            else {
                main.widgetLoaded = true;
            }
        }

        function getAllTheatres() {
            theatresService.getAllTheatres(main.languageCode).then(theatresServicesSuccess, showError);
        }

        function theatresServicesSuccess(response) {
            var theatreList = mapTheatreObjects(response);
            main.allTheatres = theatresList;
            main.widgetLoaded = true;
            scopeApply();
        }

        function updateTheatreType(theatreList, updateTheatreList, typeString) {
            for (var i = 0; i < theatreList.length; i++) {
                for (var j = 0; j < updateTheatreList.length; j++) {
                    if (theatreList[i].id === updateTheatreList[j].locationId) {
                        theatreList[i].type = typeString;
                        j = updateTheatreList.length;
                        updateTheatreList.splice(j, 1);
                    }
                }
                if (updateTheatreList.length === 0) i = theatreList.length;
            }
            return theatreList;
        }

        //Selectize Functions

        function initDateInput() {
            $(this.$control_input[0]).attr("readonly", "true");
            $(this.$control).css("cursor", "pointer");
            return true;
        }

        function selectizeAdjustOnOpen() {
            var self = this;
            main.selectizePlaceholder = self.settings.placeholder;
            self.settings.placeholder = main.selectedPlaceholder;
            self.updatePlaceholder();
            self.$wrapper.addClass("selectize-open");
            self.$wrapper.parent().addClass("selectize-open");
            self.$input.data("selectize").setActiveOption(self.$dropdown_content.find('[data-selectable]:first'));
            self.$input.data("selectize").clear(false);
            self.$dropdown_content.scrollTop(0);
            if ($(window).width() < 769) {
                $("body, html").animate({ scrollTop: (self.$wrapper.offset().top - 10) }, 500);
            }
        }

        function selectizeAdjustOnOpenDate() {
            var self = this;
            self.$wrapper.addClass("selectize-open");
            self.$wrapper.parent().addClass("selectize-open");
            self.$input.data("selectize").setActiveOption(self.$dropdown_content.find('[data-selectable]:first'));
            self.$input.data("selectize").clear(false);
            self.$dropdown_content.scrollTop(0);
            if ($(window).width() < 769) {
                $("body, html").animate({ scrollTop: (self.$wrapper.offset().top - 10) }, 500);
            }
        }

        function selectizeAdjustOnClose() {
            var self = this;
            self.$wrapper.removeClass("selectize-open");
            self.$wrapper.parent().removeClass("selectize-open");
            self.settings.placeholder = main.selectizePlaceholder;
            self.updatePlaceholder();
        }

        function selectizeAdjustOnCloseDate() {
            var self = this;
            self.$wrapper.removeClass("selectize-open");
            self.$wrapper.parent().removeClass("selectize-open");
        }

        function searchByTheatreNoTheatreResults() {
            var self = this;
            if (!self.hasOptions) {
                main.noTheatreResults = true;
            }
            else {
                main.noTheatreResults = false;
            }
            scopeApply();
        }

        function searchByTheatreNoMovieResults() {
            var self = this;
            if (!self.hasOptions) {
                main.noMovieResults = true;
            }
            else {
                main.noMovieResults = false;
            }
            scopeApply();
        }

        function searchByMovieNoTheatreResults() {
            var self = this;
            if (!self.hasOptions) {
                main.searchByMovie.noTheatreResults = true;
            }
            else {
                main.searchByMovie.noTheatreResults = false;
            }
            scopeApply();
        }

        function searchByMovieNoMovieResults() {
            var self = this;
            if (!self.hasOptions) {
                main.noMovieResults = true;
            }
            else {
                main.noMovieResults = false;
            }
            scopeApply();
        }

        function clearErrors() {
            main.noMovieResults = false;
            main.noTheatreResults = false;
            scopeApply();
        }

        //General Functions

        function showError(error) {
            main.widgetLoaded = true;
            main.showFallbackError = true;
            scopeApply();
        }

        function uniqueArray(arrayObject) {
            var uniqueArr = [];
            for (var i = 0; i < arrayObject.length; i++) {
                if (uniqueArr.indexOf(arrayObject[i]) === -1) {
                    uniqueArr.push(arrayObject[i]);
                }
            }
            return uniqueArr;
        }

        function createPromoLinkForShowtime(showtimeLink) {
            return showtimeLink + "&PromotionCode=" + main.promoCode;
        }

        function formatAndFilterDateResults(dates) {
            var movieDates = [];
            $.each(dates, function (index, dateItem) {

                var language = "EN";
                if (main.languageCode === "fr-ca") {
                    language = "FR";
                }

                var englishDate = moment.utc(dateItem, undefined, "EN", true);
                var englishFormat = englishDate.format("ddd, MMM DD YYYY");

                var movieDate = moment.utc(dateItem, undefined, language, true);

                if (main.isAllDayAllowed || main.datesEligable.indexOf(movieDate.day()) > -1) {

                    var dateItemFormatted = language === "EN" ? movieDate.format("ddd, MMM DD YYYY") : movieDate.format("ddd MMM DD YYYY"),
                        localTime = moment();

                    if (englishDate.format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")) {
                        movieDates.push(new dateObject(index, ("(" + main.todayText + ") " + dateItemFormatted), englishFormat));
                    }
                    else if (englishDate.format("MM-DD-YYYY") === localTime.add(1, 'days').format('MM-DD-YYYY')) {
                        movieDates.push(new dateObject(index, ("(" + main.tomorrowText + ") " + dateItemFormatted), englishFormat));
                    }
                    else {
                        movieDates.push(new dateObject(index, dateItemFormatted, englishFormat));
                    }
                }
            });
            return movieDates;
        }

        /*TODO ROSS: REFACTOR THIS TO WORK WITH DAYS THAT ONLY HAVE A MIDNIGHT SHOWTIME*/
        function addMidnightShowtimeProperties(showtimes) {
            for (var showtimeDetail in showtimes) {
                if (showtimes.hasOwnProperty(showtimeDetail)) {
                    var showtimesByExperience = showtimes[showtimeDetail].showtimes;
                    var firstShowTimeDate = moment.utc(showtimesByExperience[0].showStartDateTimeUtc).format("MM/DD/YYYY");
                    for (var showtime in showtimesByExperience) {
                        if (showtimesByExperience.hasOwnProperty(showtime)) {
                            var showStartDate = moment.utc(showtimesByExperience[showtime].showStartDateTimeUtc).format("MM/DD/YYYY");
                            if (showStartDate > firstShowTimeDate) {
                                main.midnightShowtimes = true;
                                showtimesByExperience[showtime].midnightShowtime = true;
                                showtimesByExperience[showtime].midnightShowtimeDay = moment.utc(showStartDate).format("ddd");
                            }
                        }
                    }
                }
            }
            return showtimes;
        }

        function getSelectedItem(itemArray, itemId) {
            return itemArray.filter(function (item) { return item.id === parseInt(itemId); })[0];
        }

        function mapMovieObjects(movieObjects) {
            var result = [];

            movieObjects.forEach(function (movie) {
                result.push(new movieObject(movie.value, movie.key, movie.movieUtcDates, "mov", movie.seriesTicketingUrl, movie.seriesTicketingVIPUrl));
            });

            return result;
        }


        function mapTheatreObjects(theatresArray) {
            var mappedArray = [];
            theatresArray.forEach(function (theatre) {
                //Hacky filtering for Boucherville Drive-in theatre (I don't like how this is done, but I have been left no choice)
                if (parseInt(theatre.locationId) !== 9112) {
                    mappedArray.push(new theatreObject(theatre.name, theatre.locationId, "regular", theatre.city, theatre.province, theatre.movieUtcDates));
                }
            });
            return mappedArray;
        }

        function theatreObject(name, id, type, city, province, movieUtcDates) {
            return {
                text: name,
                id: id,
                type: type,
                searchTerm: name + " " + city + " " + province,
                movieUtcDates: movieUtcDates || [],
            };
        }

        function movieObject(name, id, dates, type, seriesTicketingUrl, seriesTicketingVIPUrl) {
            return {
                text: name,
                id: id,
                movieUtcDates: dates || [],
                type: type,
                seriesTicketingUrl: seriesTicketingUrl,
                seriesTicketingVIPUrl: seriesTicketingVIPUrl
            };
        }

        function dateObject(id, text, dateText) {
            return {
                id: id,
                text: text,
                dateText: dateText,
                type: "date"
            };
        }

        function trackClick(targetText) {
            trackCustomClickEvent(targetText);
        }

        function scopeApply() {
            $timeout(function () { $scope.$apply(); });
        }
    }
})();